import DynamicHeadTag from "components/misc/DynamicHeadTag";
import { useEffect } from "react";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import savingsHeroImg from "assets/images/bgs/new-savings-hero.svg";
import flexSavingsImg from "assets/images/bgs/flex-savings-img.svg";
import arrowWhite from "assets/images/icons/arrow-right-white.svg";
// import savingsForYouImg from "assets/images/bgs/savings-for-you-frame.svg";
import savingsFlexImg from "assets/images/bgs/savings-flex-img.svg";
import goalSavingsImg from "assets/images/bgs/goal-savings-img.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import personalSavingsNetworkImg from "assets/images/icons/personal-savings-network-icon.svg";
import flexSavingsRightLine from "assets/images/bgs/flex-savings-right-line.svg";
import flexSavingsLeftLine from "assets/images/bgs/flex-savings-left-line.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import DollarSavingsOtw from "components/common/dollar-jar";
import { useMediaQuery } from "hooks/useMediaQuery";


const ProductsSavings = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="Grow Your Savings with Earnipay - Start Saving Today!"
				headerDescription="Discover the power of saving with Earnipay. Earn high-interest rates and achieve your money goals effortlessly. Start saving today and secure your financial future."
			/>			

				<div className="flex flex-col page-header-gradient-half items-center text-center pt-16">
					<div className="text-5xl font-medium text-ep-primary powering-business-title">
						Put Your Money To Work
					</div>
					<div className="text-gray-600 mt-6 access-high-yields">
						Unlock higher returns and watch your money multiply with interest paid daily at market leading rates.
					</div>
					<div className="pt-4 md:pt-10 first-landing-playstore-btns">
						<PlayStoreButtons />
					</div>

					<div className="mt-14">
						<img src={savingsHeroImg} className="" alt="" />
					</div>
				</div>

				<div className="p-3 md:p-20 flex justify-center ">
					<div className="relative flex flex-col md:flex-row space-x-5 bg-gray-100 rounded-2xl h-full mt-20 max-w-[1200px] max-h-[515px]">
						<div className="flex-1 p-5 max-w-[500px] mx-auto">
							<div className="text-2xl md:text-4xl">Make Your Money Work As Hard As You</div>
							<div className="text-gray-600 mt-2 text-sm md:text-xl">Earn market leading returns on flexible and fixed savings.</div>
							<div className="btn btn-ep-primary rounded-3xl mt-5 mb-10 px-8">Get Started <img src={arrowWhite} alt="" className="pl-2"/></div>
						</div>
						<img src={personalSavingsNetworkImg} className="absolute h-24 sm:h-28 md:h-32 xl:h-52 savings-antenna-image grayscale" alt="" />
						<div className="flex-1 flex justify-center">
							<img src={flexSavingsImg} className="w-3/5 md:w-3/4" alt="" />
						</div>
					</div>
				</div>

				<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
					backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
					backgroundRepeat: 'no-repeat',
				}}>
					<div className='flex flex-col items-center'>
						<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Savings Tailored Just For You</div>
						<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
						Get started in seconds
						</div>
					</div>
				</div>

				<div className="grid md:grid-cols-2 items-center space-y-8 md:space-y-0 md:space-x-10 mt-20 p-8">
					<div className="bg-gray-100 rounded-3xl pt-10 px-10 space-y-4" style={{backgroundImage: `url(${flexSavingsLeftLine})`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
						<div className="text-xl font-medium">Flex</div>
						<div className="text-gray-600 text-sm w-full">A savings plan that puts your spare change to work with no commitments or breaking fees, only consistent daily returns.</div>
						<div className="flex justify-center">
							<img src={savingsFlexImg} alt="" />
						</div>
					</div>
					<div className="bg-gray-100 rounded-3xl px-10 pt-10 space-y-4" style={{backgroundImage: `url(${flexSavingsRightLine})`, backgroundPosition: 'bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
						<div className="text-xl font-medium">Goal</div>
						<div className="text-gray-600 text-sm w-full">Ambitious goals need your commitment, and goal savings earn you higher returns over a fixed period.</div>
						<div className="flex justify-center">
							<img src={goalSavingsImg} alt="" />
						</div>
					</div>
				</div>

				<div className=" p-4 md:p-8 mx-auto">
					<DollarSavingsOtw />
				</div>

				<OnePlatformForMoney />
			
		</div>
	);
};

export default ProductsSavings