import React, { useEffect/* , useState */ } from "react";
// import newheroImage from "assets/images/bgs/rewardsImage.png"
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
// import Maybe from "components/common/Maybe";
// import directionRightCircle from "assets/images/icons/direction-right-circle.svg";
// import manandwomanImg from "assets/images/bgs/rewardsManandWoman.png"
// import iphoneImg from "assets/images/bgs/iPhone-12-Mockup.png"
// import secondIphoneImg from "assets/images/bgs/rewardsIphone.png"
// import NewWideBanner from "components/common/NewWideBanner";
// import rewards1 from "assets/images/bgs/rewards1.png";
// import rewards2 from "assets/images/bgs/rewards2.png";
// import rewards3 from "assets/images/bgs/rewards3.png";
import rewardsHeroImg from "assets/images/bgs/rewards-hero.svg";
// import howItWorksImg from "assets/images/bgs/how-it-works-img.svg";
import howItWorksSub1 from "assets/images/bgs/how-it-works-sub1.svg";
import howItWorksSub2 from "assets/images/bgs/how-it-works-sub2.svg";
import howItWorksSub3 from "assets/images/bgs/how-it-works-sub3.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import newRewardsIphone from "assets/images/bgs/newRewardsIphone.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import { useMediaQuery } from "hooks/useMediaQuery";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import rewardsPerksGreenBg from "assets/images/bgs/rewards-perks-green-bg.svg";


const Rewards = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="Unlock Exciting Rewards with Earnipay - Join Now and Enjoy!"
				headerDescription="Join Earnipay and reap the rewards! Earn points on every transaction and redeem them for exciting rewards. From cashback to exclusive offers, there's something for everyone."
			/>

			<div className=" flex flex-col items-center text-center pt-16 page-header-gradient-half">
				<div className="text-5xl font-medium text-ep-primary powering-business-title" style={{maxWidth: '786px'}}>
					Eat Your Cake and Have It With Rewards
				</div>
				<div className="text-gray-600 mt-6 access-high-yields">
					Get rewarded for transactions with discounts, bonuses, and points redeemable at your favourite outlets.
				</div>
				<div className="pt-4 md:pt-10 first-landing-playstore-btns">
					<PlayStoreButtons />
				</div>

				<div className="mt-8 mb-16 flex justify-center">
					<img src={rewardsHeroImg} className="h-1/2w w-3/4" alt="" />
				</div>
			</div>

			<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">How To Use On-Demand Pay</div>
					<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
					Get access to your money in 3 steps
					</div>
				</div>
			</div>

			<div className="p-3 md:p-10">
				<div className="flex flex-col md:flex-row space-x-4 justify-center">
					<div className="h-auto"><img src={howItWorksSub1} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
					<div className="h-auto"><img src={howItWorksSub2} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
					<div className="h-auto"><img src={howItWorksSub3} className="h-full w-full object-cover rounded-3xl" alt="" /></div>
				</div>
			</div>

			<div className="flex justify-center mb-14">
				<div className="btn btn-ep-primary rounded-3xl mt-5 mb-10 px-8 md:hidden">Get Started With Earnipay</div>
			</div>

			<div className="md:p-10 p-3">
				<div className="text-white rounded-3xl pt-10" style={{backgroundImage: `url(${rewardsPerksGreenBg})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
					<div className="text-center p-2 md:p-0">
						<div className="text-2xl md:text-4xl">Smart Money Moves That<br /> Earn You Rewards</div>
						<div className="text-sm md:text-xl pt-4">A rewards system designed to put money back into your pocket</div>
					</div>
					<div className="flex flex-col md:flex-row justify-center items-center md:space-x-28">
						<div className="p-1 md:p-7">
							<div className="space-y-10 max-w-[450px] text-center w-full">
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Diverse Redemption Options</div>
									<div className="text-sm lg:text-base">Choose from a variety of rewards—from discounts to exclusive experiences—that match your unique preferences</div>
								</div>
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Earn Points, Unlock Rewards</div>
									<div className="text-sm lg:text-base">Every interaction on the Earnipay App is an opportunity to earn points. Accumulate them to unlock a world of perks and exciting rewards.</div>
								</div>
								<div className="rewards-green-card p-3 bg-opacity-5 bg-green-500 flex flex-col items-center justify-center">
									<div className="text-xl lg:text-2xl font-medium pb-3">Surprises Along The Way</div>
									<div className="text-sm lg:text-base">Enjoy delightful surprises and exclusive offers. We believe in making every interaction with us memorable.</div>
								</div>
							</div>
						</div>
						<div className="pt-10">
							<img src={newRewardsIphone} alt="Rewards iPhone" />
						</div>
					</div>
				</div>

			</div>

			<OnePlatformForMoney />

		</div>
	);
};

export default Rewards