import React from 'react'
import bulkPay from "assets/images/bgs/bulk-pay.png";


const BulkPayFeature = () => {
    return (
        <div className="container md:mt-0">
            <div className="bg-ep-primary-light rounded-lg md:rounded-3xl">
                <div className="sm:rounded-r-3xl pr-5 sm:rounded-s-lg flex flex-col pt-3 md:py-0 md:pr-0 md:flex-row md:justify-start md:space-x-28">
                    <div className="md:w-1/2 order-2 md:order-1 flex items-center">
                        <div className="mt-8 md:mt-0">
                            <div className="container relative z-[2] pl-12 pt-3 sm:pt-10 sm:pb-10 pb-6">
                                <div className="text-ep-primary-400 text-xs font-semibold ">
                                    BULK PAY
                                </div>
                                <div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik text-ep-primary">
                                    The fastest way to <br className="hidden xl:block" /> pay in
                                    bulk
                                </div>
                                <div className="mt-6 text-sm text-[#4A5564]">
                                    Save time, get discounts and never have to worry about a
                                    missed payment! Our Bulk Pay feature enables you to
                                    <div>
                                        <ul className="list-disc ml-6">
                                            <li>Pay thousands of vendors in one go</li>
                                            <li>
                                                Buy airtime and internet plans for your
                                                employees/contractors
                                            </li>
                                            <li>Schedule payments on the go</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="md:w-2/3 order-1 md:order-2 flex justify-end items-center ">
                        <div className="w-full flex justify-end ">
                            <div className="min-h-48 relative inline-block mx-auto md:mx-0 md:pr-5 lg:pr-0">
                                <img
                                    src={bulkPay}
                                    className="object-contain max-h-[300px] md:max-h-full rounded-3xl pr-5 md:pr-0"
                                    alt="anyday payday"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BulkPayFeature