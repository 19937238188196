import businessPaymentHeroImg from '../../assets/images/bgs/business-payment-hero-img.svg';
import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import paymentGetStartedBtn from "assets/images/bgs/payroll-get-started-btn.svg";
import businessPaymentCard1 from "assets/images/bgs/business-payment-card-1.svg";
import businessPaymentCard2 from "assets/images/bgs/business-payment-card-2.svg";
import businessPaymentCard3 from "assets/images/bgs/business-payment-card-3.svg";
import businessPaymentCard4 from "assets/images/bgs/business-payment-card-4.svg";
import greyCheckCircle from "assets/images/icons/business-payment-grey-check-circle.svg";
import CtaSection from "components/common/cta-green";
import { useMediaQuery } from 'hooks/useMediaQuery';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import businessPaymentVector from "assets/images/bgs/business-payment-vector.svg";


const BusinessPayment = () => {

  const isMobile = useMediaQuery('(max-width: 500px)');

  return (
      <div>
        <div style={{backgroundImage: `url(${businessPaymentVector})`, backgroundSize: '55% 1000px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>

        <div className="flex flex-col items-center text-center pt-16 page-header-gradient-half z-10">
          <div className="md:text-5xl font-medium text-ep-primary max-w-[750px] leading-tight powering-business-title">
            Make Payments With Precision, Everytime
          </div>
          <div className="text-gray-600 mt-2 max-w-[850px] access-high-yields">
            Control, schedule, and automate payments, whether to one recipient or a thousand.
          </div>
                  
          <div className='pt-10'>
            <div className="relative w-60 md:w-[320px] md:h-[52px] cursor-pointer"> 
              <img src={greenButton} alt="" className=" w-full h-full object-cover" />
              <div className="absolute left-14 md:left-24 top-1/4 bottom-0 text-white text-sm md:text-base">
                  Get Started
              </div>
            </div>
          </div>
          </div>

          <div className="mt-8 mb-16 flex justify-center">
            <img src={businessPaymentHeroImg} className="h-1/2w w-3/4" alt="" />
          </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Transfers That Work</div>
            <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
            Send money with confidence. Experience secure and efficient money transfer today.
            </div>
          </div>
        </div>

        <div className='md:p-5 container'>
          <div className='rounded-xl border p-2'>
            <div className='flex flex-col'>
              <div className='flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 flex-1 max-h-[630px] max-w-full h-auto'>
                <div className='flex-1'>
                    <img src={businessPaymentCard1} className=' h-full' alt="" />
                </div>
                <div className='flex-1'>
                  <div className='space-y-3 multiple-payments-container'>
                    <div className="text-[32px] lg:text-[56px] leading-tight multiple-payments">Multiple Payments Fast And Easy</div>
                    <div className="text-gray-600 leading-tight">Simplify your payments with Earnipay. Experience seamless, efficient, and secure transactions today</div>
                    <div className='space-y-2 text-gray-600'>
                      <div className='flex space-x-3'>
                        <img src={greyCheckCircle} alt="" />
                        <div className='text-[17px]'>Pay from existing beneficiaries</div>
                      </div>
                      <div className='flex space-x-3'>
                        <img src={greyCheckCircle} alt="" />
                        <div className='text-[17px]'>Intuitive Interface</div>
                      </div>
                      <div className="mt-6 cursor-pointer ">
                          <img src={paymentGetStartedBtn} className='h-[43px]' alt="" />
                      </div>
                      <div className='hidden sm:block'>
                        <img src={businessPaymentCard2} className='multiple-payments w-full h-auto' alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <hr className='w-11/12 mx-auto my-8 mt-32 md:mt-10'/>
              </div>

              <div className='flex-1 flex flex-col md:flex-row md:space-x-5 max-w-full'>
                <div className='sm:hidden'>
                  <img src={businessPaymentCard2} className='w-full h-auto' alt="" />
                </div>
                <div className='flex-1 space-y-4'>
                  <div className='space-y-3'>
                    <div className='text-[32px] lg:text-[56px] leading-tight'>Seamless Bulk Payments</div>
                    <div className='text-gray-600'>Easily manage large-scale transactions. Process bulk payments quickly and accurately to save time and reduce manual errors.</div>
                    <div className="mt-6 cursor-pointer">
                      <img src={paymentGetStartedBtn} alt="" />
                    </div>
                  </div>

                  <div>
                    <img src={businessPaymentCard3} className='w-[530px]' alt="" />
                  </div>
                </div>
                <div className='flex-1 mt-5'>
                  <div className='p-1 h-full'>
                    <div className='bg-[#F6F8FA] rounded-xl p-3 h-full flex flex-col justify-around'>
                      <img src={businessPaymentCard4} className='w-full max-w-[600px]' alt="" />
                      <div className='space-y-3 p-2'>
                        <div className='text-3xl md:text-4xl'>Split Transactions</div>
                        <div className='text-gray-600'>Effortlessly divide payments among multiple beneficiaries. Ensure precise and efficient fund distribution with just a few clicks.</div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>

        <div className='pt-20'>
          <CtaSection />
        </div>

       
    </div>
  )
}

export default BusinessPayment