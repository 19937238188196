import React, { useEffect, useState } from "react";
import heroImage from "assets/images/bgs/personal-hero.svg";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import appStoreBlack from "assets/images/icons/app-store-black.svg";
import playStoreBlack from "assets/images/icons/play-store-black.svg";
import TrustedBy from "components/common/trustedBy";
import transact from "assets/images/bgs/transact.png";
import savings from "assets/images/bgs/save-smarter.png";
import rewards from "assets/images/bgs/rewards.png";
import Maybe from "components/common/Maybe";
import directionRightCircle from "assets/images/icons/direction-right-circle.svg";
import earnipayMobile from "assets/images/icons/earnipay-mobile.png";
import download from "assets/images/bgs/download.png";
import activate from "assets/images/bgs/activate.png";
import earn from "assets/images/bgs/earn.png";


const Employees = () => {
	const [activeStep, setActiveStep] = useState(0);

	const stepContent = [
		{
			title: "DOWNLOAD",
			image: download,
			content: "Download the Earnipay App on the Google Playstore or iOS Store.",
		},
		{
			title: "ACTIVATE",
			image: activate,
			content: "Create and activate your account by following the prompts"
		},
		{
			title: "EARN",
			image: earn,
			content: "Now you can earn, transfer, save, pay bills, and more.",
		},
	];

	useEffect(() => {
        googleAnalyticsTrack();
    }, [])

	return (
		<div>
			

			<DynamicHeadTag
				headerText="Earnipay | Personal"
				headerDescription="A comprehensive solution to manage all your finance needs in one place. Access your salary whenever you want, make seamless transfers, get quick loans…"
			/>


			<div className="relative bg-ep-primary-light">
				<div className="container">
					<div className="flex justify-between xl:h-[800px] -mt-10 xl:-mt-20">
						<div className="w-full md:w-1/2 my-auto py-20 md:py-24 lg:pb-36">
							<div>
								<div className="pt-4 md:pt-0 text-3xl sm:text-4xl lg:text-5xl text-ep-primary font-bold font-aeonik">
									Let Your Salary Do
									<br className="hidden xl:block" /> More For You!
								</div>
								<div className="mt-6 text-base text-gray-600 font-aeonik">
									Redefine your paycheck potential with our suite of solutions with ease. 
									From daily access to your earned pay to growing your money with savings, bill payments, transfers, 
									and of course cashbacks and other rewards just for using the Earnipay App. Why settle for the ordinary 
									when your salary can do more?
								</div>
								<div className="mt-10 sm:flex sm:flex-wrap space-x-3">
									<a href="https://apps.apple.com/ng/app/earnipay/id1582293975" className="h-10 inline-block">
										<img src={appStoreBlack} alt="app store" className="w-full h-full object-contain" />
									</a>
									<a href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay" className="h-10 inline-block">
										<img src={playStoreBlack} alt="play store" className="w-full h-full object-contain" />
									</a>
								</div>
							</div>
						</div>

						<div className="w-1/2 h-full pb-0 p-20 absolute bottom-0 right-0 hidden md:flex xl:items-end justify-center bg-hero-circle-full">
							<img src={heroImage} alt="earnipay hero" className="object-contain max-w-xl" />
						</div>
					</div>
				</div>
				<div></div>
			</div>


			<div className="pt-4 pb-2">
				<TrustedBy />
			</div>

			{/* <div className="container mt-4 md:mt-0 py-14 md:py-24">
				<div className="flex flex-col md:flex-row md:justify-between md:space-x-28">
					<div className="md:w-1/2 flex items-center">
						<div className="w-full flex">
							<div className="min-h-48 relative inline-block mx-auto">
								<img src={payday} className="object-contain max-h-[200px] md:max-h-[350px]" alt="Any day is payday" />
							</div>
						</div>
					</div>
					<div className="md:w-1/2 flex items-center">
						<div className="mt-8 md:mt-0">
							<div className="relative z-[2]">
								<div className="text-ep-primary-400 text-xs font-semibold">
									ON-DEMAND PAY
								</div>
								<div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik">
									Any day is payday
								</div>
								<div className="mt-6 text-gray-600 text-base">
									No more waiting until the end of the month to get paid. Get access to your pay when you need it to live your life on your terms.
								</div>
								<div className="mt-8">
									<Link to="/book-demo" className="btn btn-ep-primary">
										Get Started
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			<div className="container mt-10">
				<div className="container py-14 bg-ep-primary-light rounded-3xl">
					<div className="h-full flex flex-col md:flex-row md:justify-between md:space-x-28">
						<div className="md:w-1/2 order-2 md:order-1 flex items-center">
							<div className="mt-8 md:mt-0">
								<div className="relative z-[2]">
									<div className="text-ep-primary-400 text-xs font-semibold">
										BILL PAYMENTS, WALLET & TRANSFER
									</div>
									<div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik">
										Transact with ease
									</div>
									<div className="mt-6 text-gray-600 text-base">
										Pay your bills, send and receive money easily on Earnipay, hassle-free. No more missed deadlines or late fees, just fast and easy payments wherever you are.
									</div>
								</div>
							</div>
						</div>
						<div className="md:w-1/2 order-1 md:order-2 flex items-center">
							<div className="w-full flex">
								<div className="min-h-48 relative inline-block mx-auto">
									<img src={transact} className="object-contain max-h-[300px] md:max-h-[350px]" alt="BILL PAYMENTS, WALLET & TRANSFER" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			

			<div className="container mt-4 md:mt-0 py-14 md:py-24">
				<div className="flex flex-col md:flex-row md:justify-between md:space-x-28">
					<div className="md:w-1/2 flex items-center">
						<div className="w-full flex">
							<div className="min-h-48 relative inline-block mx-auto">
								<img src={savings} className="object-contain max-h-[200px] md:max-h-[350px]" alt="SAVINGS" />
							</div>
						</div>
					</div>
					<div className="md:w-1/2 flex items-center">
						<div className="mt-8 md:mt-0">
							<div className="relative z-[2]">
								<div className="text-ep-primary-400 text-xs font-semibold">
									SAVINGS
								</div>
								<div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik">
									Save smarter, not harder
								</div>
								<div className="mt-6 text-gray-500 text-base">
									With flexible options and competitive interest rates, you'll reach your financial goals in no time.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="container mt-10">
				<div className="container py-14 bg-ep-primary-light rounded-3xl">
					<div className="flex flex-col md:flex-row md:justify-between md:space-x-28">
						<div className="md:w-1/2 order-2 md:order-1 flex items-center">
							<div className="mt-8 md:mt-0">
								<div className="relative z-[2]">
									<div className="text-ep-primary-400 text-xs font-semibold">
										REWARDS
									</div>
									<div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik">
										Earn Points and Redeem Rewards
									</div>
									<div className="mt-6 text-gray-500 text-base">
										Turn your everyday transactions into opportunities for savings, perks, and more. We believe your financial journey should be rewarding at every step.
									</div>
								</div>
							</div>
						</div>
						<div className="md:w-1/2 order-1 md:order-2 flex items-center">
							<div className="w-full flex">
								<div className="min-h-48 relative inline-block mx-auto">
									<img src={rewards} className="object-contain max-h-[300px] md:max-h-[350px]" alt="FINANCIAL EDUCATION" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title text-ep-primary" style={{ textAlign: "left", maxWidth: "none" }}>
					How to sign up <br className="hidden xl:block" /> on Earnipay
				</div>

				<div className="mt-10 lg:flex">
					<div className="w-full lg:w-2/5 flex lg:flex-col lg:pr-20 lg:space-y-2">
						{[...Array(3)].map((data, index) => (
							<div key={index} onClick={() => setActiveStep(index)} className={`
								${activeStep === index ? "step-active border-ep-primary" : "border-transparent lg:border-gray-100"}
								w-full lg:mr-16 px-4 py-5 flex justify-between border-b-2 lg:border-b-0 lg:border-l-2 rounded-sm cursor-pointer`}
							>
								<div className="text-ep-primary uppercase font-bold text-base mx-auto lg:mx-0">
									Step {index + 1}
								</div>
								<Maybe condition={activeStep === index}>
									<img src={directionRightCircle} alt="Open Factoring" className="hidden lg:block" />
								</Maybe>
							</div>
						))}
					</div>
					<div className="w-full lg:w-3/5 p-10 sm:flex sm:space-x-12 sm:justify-between bg-ep-primary-light border rounded-xl">
						<div className="sm:w-1/2">
							<div className="font-bold">{stepContent[activeStep].title}</div>
							<div className="text-base mt-4 min-h-[100px]">
								{stepContent[activeStep].content}
							</div>
						</div>
						<div className="mt-8 sm:mt-0 sm:w-1/2 max-h-[400px]">
							<img src={stepContent[activeStep].image} alt={stepContent[activeStep].title} className="w-full h-full object-contain" />
						</div>
					</div>
				</div>
			</div>
			
			<div className="container my-14 lg:my-20">
				<div className="container py-14 bg-[#F9FAFB] rounded-3xl">
					<div className="flex flex-col items-center md:flex-row md:justify-between md:items-start md:space-x-28">
						<div className="md:w-1/2 flex items-center">
							<div className="w-full flex">
								<div className="min-h-48 relative inline-block mx-auto">
									<img src={earnipayMobile} className="object-contain max-h-[300px] md:max-h-[450px]" alt="Earnipay Mobile" />
								</div>
							</div>
						</div>
						<div className="md:w-1/2 flex items-center">
							<div className="mt-8 md:mt-0">
								<div className="flex items-center justify-center flex-col">
									<div className="mt-4 text-2xl md:text-3xl font-medium font-aeonik">
										Get Earnipay for Mobile
									</div>
									<div className="mt-5 md:mt-10 flex items-center justify-center md:flex-col space-x-3 md:space-x-0 md:space-y-5">
										<a href="https://apps.apple.com/ng/app/earnipay/id1582293975" className="h-10 md:h-14 inline-block">
											<img src={appStoreBlack} alt="app store" className="w-full h-full object-contain" />
										</a>
										<a href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay" className="h-10 md:h-14 inline-block">
											<img src={playStoreBlack} alt="play store" className="w-full h-full object-contain" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			
		</div>
	);
};

export default Employees;
