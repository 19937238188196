import React, { useState } from "react";
import logo from "assets/images/logo-dark-green.svg";
import DropdownNew from "utils/dropdown-new";
import DropdownNewBlock from "utils/dropdown-new-block";
import { Link } from "react-router-dom";
import facebook from "assets/images/social/facebook-icon.svg";
import twitter from "assets/images/social/twitter-icon.svg";
import instagram from "assets/images/social/instagram-icon.svg";
import linkedin from "assets/images/social/linkedin-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import angleDownThin from "assets/images/icons/angle-down-thin.svg";
import chevronRightWhite from "assets/images/icons/chevron-right-white.svg";


const Header = () => {
	const [mobileNav, setMobileNav] = useState(false);

	const toggleMobileNav = () => {
		const mobileMenu = document.querySelector(".mobile-menu");
		const navIcon = document.querySelector("#nav-icon");

		if (!mobileNav) {
			mobileMenu?.classList.remove("hidden");
			navIcon?.classList.add("open");
		} else {
			mobileMenu?.classList.add("hidden");
			navIcon?.classList.remove("open");
		}

		setMobileNav(!mobileNav);
	};

	return (
		<React.Fragment>
			{/* <HeaderText /> */}

			<div className="font-aeonik h-16 fixed z-50 top-0 left-0 right-0 flex landing-page-header-gradient-full bg-ep-primary-light border-b mb-0 header-wrapper">
				<div className="container my-auto">
					<div className="flex ">
						<Link to="/" className="h-10 p-1 my-auto flex-shrink-0 flex">
							<img src={logo} alt="Earnipay logo" className="my-auto h-full object-cover earnipay-logo" />
						</Link>

						<div className="hidden lg:flex pl-10 space-x-2 lg:space-x-10 justify-center items-center text-sm mr-[-4rem]">
							<div>
								<DropdownNew>
									<div className="btn flex items-center">
										<span className="text-base">Business</span>
										<img src={angleDownThin} className="self-center header-chevron-down-icon" alt="" />
									</div>
									<div className="font-normal flex flex-col">
										<Link to="/business/account" className="dropdown-item">Account</Link>
										<Link to="/business/credit" className="dropdown-item">Credit</Link>
										{/* <Link to="/business/odp" className="dropdown-item">On-Demand Pay</Link>
										<Link to="/business/payment" className="dropdown-item">Payment</Link>
										<Link to="/business/payroll" className="dropdown-item">Payroll</Link> */}
									</div>
								</DropdownNew>
							</div>
						</div>

						<div className="flex ml-auto">
							<div className="items-center h-10 hidden lg:flex flex-grow md:space-x-4 ml-10 my-auto justify-end">				
								
								<Link 
								className="text-base"
								>
								Download the Earnipay App
								{/* <FontAwesomeIcon
									icon="angle-down"
									className=" ml-2"
								/> */}
								</Link>

								{/* <DropdownNew>
									<div
										className="text-sm"
										>
										Sign In
										<FontAwesomeIcon
											icon="angle-down"
											className=" ml-2"
										/>
									</div>
									<div className="font-normal">
										<Link
											to={{ pathname: "https://business.earnipay.com/login" }}
											target="_blank"
											rel="noreferrer"
											className="dropdown-item">
											As employer
										</Link>
										<Link
											to={{ pathname: process.env.REACT_APP_EMPLOYEE_APP_URL + "/login" }}
											target="_blank"
											rel="noreferrer"
											className="dropdown-item">
											As employee
										</Link>
									</div>
								</DropdownNew> */}
								{/* <Link
									to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }}
									target="_blank"
									rel="noreferrer"
									className="btn btn-ep-primary rounded-3xl"
									style={{ minWidth: "100px" }}>
									Sign Up									
								</Link> */}

								<Link
									to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }}
									target="_blank"
									rel="noreferrer"
								>
									<button className="header-signup-btn">
										<span>Sign Up</span>
										{/* <ChevronDownWhite /> */}
										<img src={chevronRightWhite} alt="chevron-right" />
									</button>
								</Link>
							</div>
							

							<div className="h-9 lg:hidden flex">
								<div onClick={toggleMobileNav} id="nav-icon">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-10 mobile-menu hidden bg-blur w-full h-full fixed z-40 inset-0 overflow-auto overscroll-contain">
				<div className="w-full h-full flex bg-white bg-opacity-50">
					<div className="my-auto pt-24 pb-20">
						<div className="w-screen">
							<div className="header-links-mobile">
								<Link to="/" onClick={toggleMobileNav}>
									Home
								</Link>
							</div>

							{/* 
							<div className="header-links-mobile">
								<Link to="/products/invoice" onClick={toggleMobileNav}>
									Invoice Financing
								</Link>
							</div>
							<div className="header-links-mobile">
								<Link to="/products/payroll" onClick={toggleMobileNav}>
									Payroll processing and Financing
								</Link>
							</div>
							<div className="header-links-mobile">
								<Link to="/products/on-demand-pay" onClick={toggleMobileNav}>
									On-Demand Pay
								</Link>
							</div>
							<div className="header-links-mobile">
								<Link to="/products/bulk-payment" onClick={toggleMobileNav}>
									Bulk Bill & Vendor Payment
								</Link>
							</div>
							 */}

							{/* <DropdownNewBlock as="div" className="w-full">
								<div className="header-links-mobile">
									<div className="block">
										Personal
										<FontAwesomeIcon icon="angle-down" className="font-bold ml-1" />
									</div>
								</div>
								<div>
									<Link to="/personal/credit" onClick={toggleMobileNav} className="dropdown-item-large">Loans</Link>
									<Link to="/personal/on-demand-pay" onClick={toggleMobileNav} className="dropdown-item-large">On-Demand Pay</Link>
									<Link to="/personal/rewards" onClick={toggleMobileNav} className="dropdown-item-large">Rewards</Link>
									<Link to="/personal/savings" onClick={toggleMobileNav} className="dropdown-item-large">Savings</Link>
								</div>
							</DropdownNewBlock> */}

							<DropdownNewBlock as="div" className="w-full">
								<div className="header-links-mobile">
									<div className="block">
										Business
										<FontAwesomeIcon icon="angle-down" className="font-bold ml-1" />
									</div>
								</div>
								<div>
									<Link to="/business/account" onClick={toggleMobileNav} className="dropdown-item-large">Accounts</Link>
									<Link to="/business/credit" onClick={toggleMobileNav} className="dropdown-item-large">Credit</Link>
									{/* <Link to="/personal/on-demand-pay" onClick={toggleMobileNav} className="dropdown-item-large">On-Demand Pay</Link>
									<Link to="/personal/rewards" onClick={toggleMobileNav} className="dropdown-item-large">Rewards</Link>
									<Link to="/personal/savings" onClick={toggleMobileNav} className="dropdown-item-large">Savings</Link> */}
								</div>
							</DropdownNewBlock>

							{/* <div className="header-links-mobile">
								<Link to="/company" onClick={toggleMobileNav}>Company</Link>
							</div> */}

							{/* 
                            <div className="header-links-mobile">
                                <Link to="#" onClick={toggleMobileNav}>Industries</Link>
                            </div>
                            
                            <div className="header-links-mobile">
                                <Link to="#" onClick={toggleMobileNav}>Resources</Link>
                            </div>
                             */}

							{/* <DropdownNewBlock as="div" className="w-full">
								<div className="header-links-mobile">
									<div className="block">
										Login
										<FontAwesomeIcon icon="angle-down" className="font-bold ml-1" />
									</div>
								</div>
								<div>
									<Link
										to={{ pathname: "https://business.earnipay.com/login" }}
										target="_blank"
										rel="noreferrer"
										className="dropdown-item-large">
										As employer
									</Link>
									<Link
										to={{ pathname: process.env.REACT_APP_EMPLOYEE_APP_URL + "/login" }}
										target="_blank"
										rel="noreferrer"
										className="dropdown-item-large">
										As employee
									</Link>
								</div>
							</DropdownNewBlock> */}
							<div className="header-links-mobile">
								<Link to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }} target="_blank" rel="noreferrer" onClick={toggleMobileNav}>
									Signup
								</Link>
							</div>
							<div className="flex mt-10">
								<div className="mx-auto flex space-x-6">
									<Link
										to={{ pathname: "https://www.facebook.com/Earnipay" }}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social">
										<img src={facebook} alt="facebook" />
									</Link>
									<Link
										to={{ pathname: "https://www.twitter.com/earnipay" }}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social">
										<img src={twitter} alt="instagram" />
									</Link>
									<Link
										to={{ pathname: "https://www.instagram.com/getearnipay/" }}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social">
										<img src={instagram} alt="instagram" />
									</Link>
									<Link
										to={{
											pathname: "https://www.linkedin.com/company/earnipay",
										}}
										target="_blank"
										onClick={toggleMobileNav}
										className="header-links-social">
										<img src={linkedin} alt="linkedin" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
