import { Link } from "react-router-dom"
import Maybe from "./Maybe"

const WideBanner = (props) => {
    return (
        <div className="container my-14 sm:my-20 lg:my-28">
            <div className="container relative bg-ep-primary-600 rounded-3xl font-aeonik">
                <div className="relative z-10 py-20 sm:py-24 px-6 md:px-12 max-w-5xl flex items-center text-center flex-col mx-auto">
                    <div className="text-2xl md:text-4xl font-medium text-[#E5FDFF]">
                        {props.title}
                    </div>
                    <div className="mt-5 text-base text-[#B8C9D0]">
                        {props.description}
                    </div>
                    <div className="flex space-x-4 mt-5">
                        <Maybe condition={props?.openInNewTab && props?.openInNewTab === true}>
                            <Link to={{ pathname: props.buttonLink }} target="_blank" rel="noreferrer" className="btn btn-lg bg-[#B6F1E0] text-ep-primary">
                                {props.buttonText}
                            </Link>
                        </Maybe>
                        <Maybe condition={!props?.openInNewTab || props?.openInNewTab === false}>
                            <Link to={props.buttonLink} className="btn btn-lg bg-[#B6F1E0] text-ep-primary">
                                {props.buttonText}
                            </Link>
                        </Maybe>
                    </div>
                </div>
                <div className="bg-img-under bg-curves"></div>
            </div>
        </div>
    )
}

export default WideBanner
