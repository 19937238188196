import React from 'react'
import anyDayPayday from "assets/images/bgs/manage-people.png";


const PayrollFeature = () => {
    return (
        <div className="container mt-16 ">
            <div className="py-10 w-full rounded-3xl p-10 sm:p-0 ">
                <div className="w-full flex flex-col md:flex-row md:justify-between rounded-3xl p-10 lg:p-0">
                    <div className="md:w-[45%] flex items-center ">
                        <div className="w-full flex">
                            <div className="min-h-48 relative inline-block mx-auto md:mx-0 rounded-3xl">
                                <img
                                    src={anyDayPayday}
                                    className="object-contain max-h-[300px] md:max-h-full rounded-3xl pl-5 md:pl-0"
                                    alt="manage people"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-[42%] flex items-center pr-3 rounded-3xl">
                        <div className="mt-8 md:mt-0 ">
                            <div className="relative z-[2]">
                                <div className="text-ep-primary-400 text-xs font-semibold">
                                    PAYROLL
                                </div>
                                <div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik text-ep-primary">
                                    Pay your people <br />
                                    with ease
                                </div>
                                <div className="mt-6 text-[#4A5564] text-base">
                                    Accelerate payroll calculations and reduce manual errors.
                                    From automatic salary computation, tax deductions, and remittance, to effortless customization and reporting.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayrollFeature