import Marquee from "react-fast-marquee";
import brandBluechip from "assets/images/bluechip-light.png";
// import brandCileasing from "assets/images/trustedBrands/c&i-leasing.png";
import brandKennediaConsulting from "assets/images/trustedBrands/kennedia-consulting.png";
// import brandEden from "assets/images/trustedBrands/eden.png";
import brandThrive from "assets/images/trustedBrands/thrive.png";
// import brandBrandco from "assets/images/trustedBrands/brandco.png";
import brandCuracel from "assets/images/curacel.svg";
// import brandSend from "assets/images/trustedBrands/send.png";
// import brandBrooksBlake from "assets/images/trustedBrands/brooks-blake.png";
// import brandFadac from "assets/images/trustedBrands/fadac.png";
// import brandGenesys from "assets/images/trustedBrands/genesys.png";
// import brandRovingheight from "assets/images/trustedBrands/roving-heights.png";
// import brandGeorgehouston from "assets/images/trustedBrands/george-houston.png";
// import brandHertz from "assets/images/trustedBrands/hertz.png";
// import brandBfree from "assets/images/trustedBrands/bfree.png";
// import brandLawpavillion from "assets/images/trustedBrands/lawpavlion.png";
// import brandOnepipe from "assets/images/trustedBrands/one-pipe.png";
// import brandHrLeverage from "assets/images/trustedBrands/hr-leverage.png";
// import brandSabor from "assets/images/trustedBrands/sabor.png";
// import brandVenia from "assets/images/trustedBrands/venia.png";
// import brandTorchmark from "assets/images/trustedBrands/torchmark.png";
import brandMegawatts from "assets/images/trustedBrands/megawatts.svg";
// import brandHartleys from "assets/images/trustedBrands/hartleys.png";
// import brandMagrellos from "assets/images/trustedBrands/magrellos.png";
// import brandPrunedge from "assets/images/trustedBrands/prunedge.png";
// import brandAha from "assets/images/trustedBrands/aha.png";
// import brandTuteria from "assets/images/trustedBrands/tuteria.png";
// import brandCompliance from "assets/images/trustedBrands/compliance.png";
// import brandFez from "assets/images/trustedBrands/fez.png";
// import brandTech4Dev from "assets/images/trustedBrands/tech4dev.png";
// import brandMyCover from "assets/images/trustedBrands/mycover.png";
// import brandCanaan from "assets/images/trustedBrands/canaan.png";
// import brandXyz from "assets/images/trustedBrands/xyz.png";
// import brandVillage from "assets/images/trustedBrands/village.png";
// import brandMusha from "assets/images/trustedBrands/musha.png";
// import brandV from "assets/images/trustedBrands/v.png";
// import brandVoltron from "assets/images/trustedBrands/voltron.png";
// import brandRemedial from "assets/images/trustedBrands/remedial.png";
import brandPreciseLightning from "assets/images/trustedBrands/precise-lighting-logo.png";
import brandEhaClinics from "assets/images/trustedBrands/eha.png";
// import brandMecho from "assets/images/trustedBrands/mecho.png";
// import brandTotalData from "assets/images/trustedBrands/total-data.png";
import brandMaxng from "assets/images/max.svg";
import brandNutscakes from "assets/images/trustedBrands/nuts-cakes.png";
// import brandNxt from "assets/images/trustedBrands/nxt.png";
// import brandSmartflow from "assets/images/trustedBrands/smartflow.png";
// import brandTranex from "assets/images/trustedBrands/tranex.png";
// import brandReleaf from "assets/images/trustedBrands/releaf.png"


const brands = [
    {
        name: "EHA Clinics",
        logo: brandEhaClinics,
        classes: "h-7",
    },
    {
        name: "Maxng",
        logo: brandMaxng,
        classes: "h-8",
    },
    {
        name: "nutscakes",
        logo: brandNutscakes,
        classes: "h-8",
    },
    {
        name: "Thrive Agric",
        logo: brandThrive,
        classes: "h-12",
    },
    {
        name: "Kennedia Consulting",
        logo: brandKennediaConsulting,
        classes: "h-20",
    },
    {
        name: "Megawatts",
        logo: brandMegawatts,
        classes: "h-8",
    },
    {
        name: "Precise Lightning",
        logo: brandPreciseLightning,
        classes: "",
    },
    {
        name: "Curacel",
        logo: brandCuracel,
        classes: "h-7",
    },
    {
        name: "Bluechip",
        logo: brandBluechip,
        classes: "h-25",
    },
    /* {
        name: "C&I leasing",
        logo: brandCileasing,
        classes: "h-14",
    },
    {
        name: "Tranex",
        logo: brandTranex,
        classes: "h-9",
    },
    {
        name: "Hertz",
        logo: brandHertz,
        classes: "h-12",
    },
    {
        name: "Total Data",
        logo: brandTotalData,
        classes: "h-14",
    },
    {
        name: "Hr Leverage",
        logo: brandHrLeverage,
        classes: "h-8",
    },
    {
        name: "Mecho",
        logo: brandMecho,
        classes: "h-6",
    },
    {
        name: "Hartleys",
        logo: brandHartleys,
        classes: "h-7",
    },
    {
        name: "Magrellos",
        logo: brandMagrellos,
        classes: "h-10",
    },
    {
        name: "smartflow",
        logo: brandSmartflow,
        classes: "h-9",
    },
    {
        name: "Prunedge",
        logo: brandPrunedge,
        classes: "h-8",
    },
    {
        name: "Remedial",
        logo: brandRemedial,
        classes: "h-8",
    },
    {
        name: "Brand Co",
        logo: brandBrandco,
        classes: "h-6",
    },
    {
        name: "Advantage Health Africa",
        logo: brandAha,
        classes: "h-9",
    },
    {
        name: "Eden",
        logo: brandEden,
        classes: "h-9",
    },
    {
        name: "FEZ Delivery",
        logo: brandFez,
        classes: "h-7",
    },
    {
        name: "Technology for Social Change and Development Initiative",
        logo: brandTech4Dev,
        classes: "h-7",
    },
    {
        name: "MyCover",
        logo: brandMyCover,
        classes: "h-8",
    },
    {
        name: "venia",
        logo: brandVenia,
        classes: "h-10",
    },
    {
        name: "onepipe",
        logo: brandOnepipe,
        classes: "h-7",
    },
    {
        name: "roovingheight",
        logo: brandRovingheight,
        classes: "h-8",
    },
    {
        name: "Tuteria",
        logo: brandTuteria,
        classes: "h-7",
    },
    {
        name: "Compliance",
        logo: brandCompliance,
        classes: "h-7",
    },
    {
        name: "Send",
        logo: brandSend,
        classes: "h-8",
    },
    {
        name: "torchmark",
        logo: brandTorchmark,
        classes: "h-6",
    },
    {
        name: "genesys",
        logo: brandGenesys,
        classes: "h-7",
    },
    {
        name: "releaf",
        logo: brandReleaf,
        classes: "h-6",
    }, */
]


const TrustedBy = () => {

    return (
        <div className="my-16 sm:my-20 lg:mt-28">{/* container */}
            <div className="page-title leading-business">
                Our Leading Businesses
            </div>
            <div className="mt-6 sm:mt-10 lg:mt-12">

                <div className="flex flex-wrap justify-center mx-auto">
                    <Marquee speed="50" pauseOnHover={true}>
                        {brands.map((brand, index) => 
                            <div key={index} className={brand.classes + " flex-shrink-0 mx-8 xl:mx-28 flex grayscale hover:grayscale-0"}>
                                <img src={brand.logo} alt={brand.name} className="w-min sm:w-full h-full my-auto object-contain" />
                            </div>
                        )}
                    </Marquee>
                </div>

            </div>
        </div>
    )
}


export default TrustedBy;
