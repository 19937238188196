import React, { useState } from "react";
// import heroBackgroundImage from "assets/images/bgs/business-payroll-line-bg.svg";
import businessOdpHeroImg from "assets/images/bgs/business-odp-hero-img.svg";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import benefits1 from "assets/images/bgs/business-odp-benefits-1.svg";
import benefits2 from "assets/images/bgs/business-odp-benefits-2.svg";
import benefits3 from "assets/images/bgs/business-odp-benefits-3.svg";
import benefits4 from "assets/images/bgs/business-odp-benefits-4.svg";
import odpCardSteps1 from "assets/images/bgs/odp-card-steps-1.svg";
import CtaSection from "components/common/cta-green";
// import greenButton from "../../assets/images/icons/green-button-revamp.svg";
import odpButton from "assets/images/icons/business-odp-button-img.svg";
import Testimonials from "components/common/testimony";
import { useMediaQuery } from 'hooks/useMediaQuery';
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";
import businessOdpGreenBlocks from "assets/images/bgs/business-odp-green-blocks.svg";



const OnDemandPay = () => {

    const isMobile = useMediaQuery('(max-width: 500px)');

    const odpBenefits = [
        {
            image: benefits1,
            title: "Zero Cost",
            content: "Unlock growth without the price tag. Empower your workforce with On-demand Pay at Zero cost to you.",
        },
        {
            image: benefits2,
            title: "No Payroll Disruption",
            content: "Make your employees happy without disrupting your cashflow.",
        },
        {
            image: benefits3,
            title: "Work and Pay Cycle Gap Solved",
            content: "Reduce employee reliance on payday loans with access to the money they’ve earned as they earn it.",
        },
        {
            image: benefits4,
            title: "All-Day Support",
            content: "Easily set limits, customize access, and ensure usage that aligns with your business policies.",
        },
    ]

    const odpCardSteps = [
        {
            image: odpCardSteps1,
            title: 'Verify Eligibility Effortlessly',
            content: 'Submit a bank statement for seamless verification of On-Demand Pay eligibility.',
        },
        {
            // image: odpCardSteps2,
            title: 'Customize Employee Benefits',
            content: 'Tailor On-Demand Pay settings: set access percentages, approval requirements, and manage withdrawal fees.',
        },
        {
            // image: odpCardSteps3,
            title: 'Invite Your Team to Financial Flexibility',
            content: 'Automatically enroll your employees for On-Demand Pay to enhance financial wellness.',
        },
    ]

    const [activeOdpStep, setActiveOdpStep] = useState(0);

  return (
    <div>
        <section 
            className="w-full h-[100%] bg-cover bg-center page-header-gradient-half mb-20"
            >
            <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />

            <div className="flex flex-col justify-center items-center ">
                <div className="text-5xl font-medium text-center text-ep-primary-600 pt-20 powering-business-title" 
                    style={{color: '#093D42', maxWidth: '800px'}}
                >
                    Help Your People Win By Giving Them A Choice
                </div>
                <div className="text-gray-600 text-center mt-5 access-high-yields">
                    Give your employees access to the money they’ve earned as they earn it, at zero cost to you.
                </div>
                <div className='pt-5'>
                    <img src={odpButton} alt="" className="p-2 md:p-0 w-full h-full object-cover" />
                </div>
            </div>
        
            <div className="flex justify-center pt-10">
                <img src={businessOdpHeroImg} alt="" style={{width:'70%',}} />
            </div>
        </section>

        <div className="" style={{backgroundImage: `url(${diamondNetBg})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: '100% 500px'}}>
            <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
                backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
                backgroundRepeat: 'no-repeat',
            }}>
                <div className='flex flex-col items-center'>
                    <div className="md:text-3xl lg:text-[56px] leading-none text-center">Unlock A Happier, Motivated Workforce</div>
                    <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
                    On-demand access to salary that's already earned, all month
                    </div>
                </div>
            </div>

            <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-16 p-4 md:p-20 justify-center mx-auto">
                {odpBenefits.map((benefit, index) => (
                    <div key={index} className=" border-2 border-gray-200/50 p-10 rounded-3xl max-w-[444px] mx-auto">
                        <img src={benefit.image} alt="" />
                        <div>
                            <div className="font-bold">{benefit.title}</div>
                            <div className="text-sm text-gray-500">{benefit.content}</div>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center">Get started with On-Demand Pay</div>
                <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
                Unlock a happier workforce in 3 steps
                </div>
            </div>
        </div>

        <div className="container mt-14 sm:mt-20 lg:mt-28">
            <div className="mt-10 lg:flex bg-[#106368] rounded-xl" 
                style={{backgroundImage: `url(${businessOdpGreenBlocks})`, 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'}}>
                <div className="w-full lg:w-2/5 flex lg:flex-col lg:pr-20 lg:space-y-5 justify-center p-10">
                    {[...Array(3)].map((data, index) => (
                        <div key={index} onClick={() => setActiveOdpStep(index)} className={`
                            ${activeOdpStep === index ? "border-[#B6ECF2]" : "border-transparent lg:border-[#1a99a1]"}
                            ${activeOdpStep === index ? "text-white" : "text-red-600"}
                            w-full lg:mr-16 px-4 flex justify-between border-b-2 lg:border-b-0 lg:border-l-[2px] rounded-sm cursor-pointer`}
                        >
                            <div className={`text-base mx-auto lg:mx-0 ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>
                                <div className="text-lg font-medium">{odpCardSteps[index].title}</div>
                                <div className={`text-ep-primary-light text-sm ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>{odpCardSteps[index].content}</div> 
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-full lg:w-3/5 justify-center sm:flex sm:space-x-12 rounded-xl">
                    <div className="mt-8 sm:mt-0 sm:w-1/2 max-h-[400px]">
                        <img src={odpCardSteps[activeOdpStep].image} alt={odpCardSteps[activeOdpStep].title} className="w-full h-full object-contain" />
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center">Empowering Employers</div>
                <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
                Discover how On-Demand Pay is reshaping workplace benefits and employee satisfaction
                </div>
            </div>
        </div>

        <Testimonials />

        <CtaSection />


    </div>
  )
}

export default OnDemandPay