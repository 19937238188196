import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import heroImage from "assets/images/bgs/products-invoice-hero.png";
import docOpen from "assets/images/icons/doc-open.svg";
import docReverse from "assets/images/icons/doc-reverse.svg";
import directionRightCircle from "assets/images/icons/direction-right-circle.svg";
import Maybe from "components/common/Maybe";
import WideBanner from "components/common/WideBanner";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";


const ProductsInvoice = () => {
	const [activeStep, setActiveStep] = useState(0);

	const stepContent = [
		{
			title: "FAST, SIMPLE APPLICATION",
			content:
				"Applying for any of our invoice financing solutions takes less than 5 minutes.",
		},
		{
			title: "SIGN UP AS A BUSINESS",
			content:
				"To process your application, we will send you a signup link. After you sign up, you will need to upload the required financial documents on your dashboard for us to review.",
		},
		{
			title: "EVALUATION",
			content:
				"We will carefully review your application to confirm the authenticity of your business and its finances.",
		},
		{
			title: "DISBURSEMENT",
			content:
				"You will receive an offer detailing our financing conditions and we will disburse payment to you or your supplier within 24-48 hours after you accept and sign our offer.",
		},
	];

	const solutions = [
		{
			image: docOpen,
			title: "Receivables financing",
			content:
				"We will pay you up to 80% of the amount upfront on the invoices you issue to your customers, so you can cover your operating costs and grow your business.",
		},
		{
			image: docReverse,
			title: "Payables financing",
			content:
				"Need to buy new products and have no working capital to pay for them? Not to worry, earnipay will pay your suppliers within Nigeria up to 80% of the total amount on invoices they issue to you.",
		},
	];


    useEffect(() => {
        googleAnalyticsTrack();
    }, [])


	return (
		<div>
			<div className="relative bg-ep-primary-light">

				<DynamicHeadTag
					headerText="Invoice Financing for Businesses | Earnipay"
					headerDescription="Turn your unpaid invoices into cash today! Earnipay will finance your payables and receivables by up to 80%"
				/>

				<div className="container">
					<div className="flex justify-between xl:h-[800px] -mt-10">
						<div className="w-full md:w-1/2 my-auto py-20 md:py-24 lg:py-36">
							<div>
								<div className="pt-4 md:pt-0 text-3xl sm:text-4xl lg:text-5xl text-ep-primary font-bold font-aeonik">
									Turn your unpaid
									<br className="hidden xl:block" /> invoices into cash!
								</div>
								<div className="mt-6 text-base text-gray-600 font-aeonik">
									Get access to immediate capital using your outstanding
									invoices. Pay your supplier and cover operational expenses
									with no downtime.
								</div>
								<div className="mt-10 sm:flex sm:flex-wrap sm:space-x-6 md:space-x-0 lg:space-x-6 space-y-2 sm:space-y-0 md:space-y-2 lg:space-y-0">
									<Link to="/book-demo" className="btn btn-ep-primary">
										Get Started
									</Link>
								</div>
							</div>
						</div>

						<div className="w-1/2 h-full p-20 absolute bottom-0 right-0 hidden md:flex justify-center bg-hero-circle-full">
							<img
								src={heroImage}
								alt="earnipay hero"
								className="object-contain max-w-xl"
							/>
						</div>
					</div>
				</div>
				<div></div>
			</div>

			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title">
					Choose the right solution for your business
				</div>
				<div className="mt-10 lg:mt-12">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
						{solutions.map((solution, index) => (
							<div key={index} className="flex">
								<div className="w-12 sm:w-20 lg:w-28 h-12 sm:h-20 lg:h-28 p-3 sm:p-4 lg:p-6 flex flex-shrink-0 mr-6 bg-gray-100 rounded-md">
									<img
										src={solution.image}
										alt={solution.title}
										className="w-full h-full my-auto object-contain"
									/>
								</div>
								<div>
									<div className="font-bold">{solution.title}</div>
									<div className="mt-1 text-gray-600 text-base">
										{solution.content}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title text-ep-primary" style={{ textAlign: "left", maxWidth: "none" }}>
					How does Invoice <br className="hidden xl:block" /> Financing work?
				</div>

				<div className="mt-10 lg:flex">
					<div className="w-full lg:w-2/5 flex lg:flex-col lg:pr-20 lg:space-y-2">
						{[...Array(4)].map((data, index) => (
							<div
								key={index}
								onClick={() => setActiveStep(index)}
								className={`${
									activeStep === index
										? "step-active border-ep-primary"
										: "border-transparent lg:border-gray-100"
								} w-full lg:mr-16 px-4 py-5 flex justify-between border-b-2 lg:border-b-0 lg:border-l-2 rounded-sm cursor-pointer`}>
								<div className="text-ep-primary uppercase font-bold text-base mx-auto lg:mx-0">
									Step {index + 1}
								</div>
								<Maybe condition={activeStep === index}>
									<img
										src={directionRightCircle}
										alt="Open Factoring"
										className="hidden lg:block"
									/>
								</Maybe>
							</div>
						))}
					</div>
					<div className="w-full lg:w-3/5 p-10 bg-ep-primary-light border rounded-xl">
						<div>
							<div className="font-bold">{stepContent[activeStep].title}</div>
							<div className="text-base mt-4 min-h-[100px]">
								{stepContent[activeStep].content}
							</div>
							<div className="mt-6">
								<Link to="/book-demo" className="btn btn-ep-primary">
									Apply Now
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<WideBanner
				title="Want a more customised invoice financing solution?"
				description={
					<>
						We understand that each company is unique and may require bespoke
						financing options.
						<br />
						If this is you
					</>
				}
				buttonLink="/book-demo"
				buttonText="Let's Talk"
			/>
		</div>
	);
};

export default ProductsInvoice;
