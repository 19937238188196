import companyMapHero from 'assets/images/bgs/company-map-hero.svg';
import companyValue1 from 'assets/images/bgs/company-value-1.svg';
import companyValue2 from 'assets/images/bgs/company-value-2.svg';
import companyValue3 from 'assets/images/bgs/company-value-3.svg';
import companyValue4 from 'assets/images/bgs/company-value-4.svg';
import companyValue5 from 'assets/images/bgs/company-value-5.svg';
import companyGreyDesert from 'assets/images/bgs/company-grey-desert.svg';
import investorCanaan from 'assets/images/bgs/company-investor-1.svg';
import investorXyz from 'assets/images/bgs/company-investor-2.svg';
import investorVillage from 'assets/images/bgs/company-investor-3.svg';
import investorVentures from 'assets/images/bgs/company-investor-4.svg';
import investorVoltron from 'assets/images/bgs/company-investor-5.svg';
import greyDiamondBg from 'assets/images/bgs/grey-diamond-bg.svg';
import rolesUnavailableImg from 'assets/images/bgs/company-roles-unavailable-img.svg';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import { useMediaQuery } from 'hooks/useMediaQuery';



const Company = () => {

const isMobile = useMediaQuery('(max-width: 500px)');

  return (
    <div>
        <section className="w-full h-[100%] pb-10 mt-0 page-header-gradient-half"
        >
            <div className="flex flex-col justify-center items-center">
                <div className="max-w-[850px]">
                    <div className="text-xl md:text-[60px] leading-tight font-medium text-center text-[#093D42] pt-20 powering-business-title">
                    Enabling Access To Affordable Financial Services
                    </div>
                    <div className="text-sm md:text-xl text-gray-600 text-center mt-5 ccess-high-yields">
                    We're a group of highly talented individuals driven by a mission to provide best-in-class financial services to businesses and their employees, leaving our mark on the world through the work that we do.
                    </div>								
                </div>
                <img src={companyMapHero} alt="" />
            </div>
        </section>

        <div className="flex flex-col text-xl justify-center items-center p-5 py-4 md:py-8 lg:py-12">
            <div className="lg:text-[56px] md:text-2xl text-xl leading-none text-center">
                Our Culture
            </div>
            <div className="text-sm md:text-lg lg:text-2xl text-center min-w-[240px] text-gray-600 pt-5">
                An obsession for our customer's success
            </div>
        </div>

        <div className='p-5 flex justify-center'>
            <div style={{backgroundImage: `url(${companyGreyDesert})`, height: 'auto', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} className='rounded-xl flex items-center justify-center text-center p-8 max-w-[1200px]'>
                At Earnipay, our company culture is more than just a buzzword; it's the heartbeat of our organization. We foster an environment where collaboration, innovation, and continuous learning thrive. We believe in working hard, supporting each other, and celebrating successes together. We're driven by our core values that call us to do the right thing always, do our best work, delight our customers, and constantly level up, all geared towards making meaningful impact in our society.
            </div>
        </div>

        <div className="flex flex-col text-xl justify-center items-center p-5 py-4 md:py-8 lg:py-12 max-w-[600px] mx-auto">
            <div className="lg:text-[56px] md:text-2xl text-xl leading-none text-center">
                Our Values
            </div>
            <div className="text-sm md:text-lg lg:text-2xl text-center min-w-[240px] text-gray-600 pt-5">
                Our values are the guiding principles of working to achieve our business goals.
            </div>
        </div>

        <div className='flex flex-col items-center font-medium text-xl md:text-4xl text-center space-y-5'>
            <div className='flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5'>
                <div className='rounded-xl max-w-[300px] flex flex-col items-center justify-center p-3' 
                    style={{backgroundColor: '#F4F7F7'}}>
                        <div className='company-cards-text'>
                            Do The Right Thing
                        </div>
                    <img src={companyValue1} className='h-64 w-64' alt="" />
                </div>
                <div className='rounded-xl max-w-[300px] flex flex-col items-center justify-center p-3' 
                    style={{backgroundColor: '#FDF7EC'}}>
                        <div className='company-cards-text'>
                        Level Up
                        </div>
                    <img src={companyValue2} className='h-64 w-64' alt="" />
                </div>
                <div className='rounded-xl max-w-[300px] flex flex-col items-center justify-center p-3' 
                    style={{backgroundColor: '#F2F3EB'}}>
                        <div className='company-cards-text'>
                        Delight Customers
                        </div>
                    <img src={companyValue3} className='h-64 w-64' alt="" />
                </div>
            </div>
            <div className='flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5'>
                <div className='rounded-xl max-w-[300px] flex flex-col items-center justify-center p-3' 
                    style={{backgroundColor: '#EAFEF8'}}>
                        Make Meaningful Impact
                    <img src={companyValue4} className='h-64 w-64' alt="" />
                </div>
                <div className='rounded-xl max-w-[300px] flex flex-col items-center justify-center p-3' 
                    style={{backgroundColor: '#F6FAF7'}}>
                        Do Your Best Work
                    <img src={companyValue5} className='h-64 w-64' alt="" />
                </div>
            </div>
        </div>

        <div className="flex flex-col text-xl justify-center items-center p-5 py-4 md:py-8 lg:py-12 mt-[100px]">
            <div className="lg:text-[56px] md:text-2xl text-xl leading-none text-center">
                Backed By Top Investors
            </div>
            <div className="text-sm md:text-lg lg:text-2xl text-center min-w-[240px] text-gray-600 pt-5">
                Driving access to affordable financial services together.
            </div>
        </div>

        <div className='max-w-[1200px] my-10 mx-auto'>
            <div className="flex justify-center items-center w-full space-x-8 overflow-hidden grayscale hover:grayscale-0">
                <img src={investorCanaan} alt="" className="w-1/5 mx-2 h-9" />
                <img src={investorXyz} alt="" className="w-1/5 mx-2 h-8" />
                <img src={investorVillage} alt="" className="w-1/5 mx-2 h-7" />
                <img src={investorVentures} alt="" className="w-1/5 mx-2 h-10" />
                <img src={investorVoltron} alt="" className="w-1/5 mx-2 h-7" />
            </div>
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
            backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greyDiamondBg})`,
            backgroundRepeat: 'no-repeat',
        }}>
            <div className='flex flex-col items-center'>
                <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Join Us</div>
                <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
                Explore exciting opportunities to shape the future with us.
                </div>
            </div>
        </div>

        <div className='flex flex-col items-center mb-20'>
            <img src={rolesUnavailableImg} alt="" className='h-72' />
            <div className='text-gray-600 text-sm md:text-2xl'>No new roles available at the moment</div>
        </div>

    </div>
  )
}

export default Company