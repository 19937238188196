// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import onePlatformMoneyNeeds from "assets/images/bgs/one-platform-for-money-needs.svg";
import { PlayStoreButtons } from "./play-store-buttons";

const OnePlatformForMoney = () => {
    return (
        <div className="mt-12 mb-12">
            <div className="container flex justify-between items-center p-10 space-x-5 one-platform-container">
                <div className="flex flex-1 flex-col text-left items-start justify-center bg-[#F6F8FA] px-10 rounded-3xl one-platform-wrapper">
                    <h3 className="one-platform-for-all-title">
                        One Platform For All Your <br /> Money Needs
                    </h3>
                    <p className="one-platform-for-all-subtext">
                        A powerful suite of solutions designed to make your
                        <br />
                        money work for you.
                    </p>

                    <div className="justify-center flex mt-16 one-platform-store">
                        <PlayStoreButtons />
                    </div>
                </div>
                <div className="flex-1">
                    <img
                        src={onePlatformMoneyNeeds}
                        className="h-full w-auto one-platform-img"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default OnePlatformForMoney;
