import { useEffect } from "react";
import heroImage from "assets/images/bgs/payroll-hero.png";
import simplifyPeopleOprations2 from "assets/images/bgs/simplify-people-oprations-2.png";
import simplifyPeopleOprations3 from "assets/images/bgs/simplify-people-oprations-3.png";
import securityConfidentiality from "assets/images/icons/security-confidentiality.svg";
import employeeExp from "assets/images/icons/employee-exp.svg";
import compliant from "assets/images/icons/compliant.svg";
import saveTimeMoney from "assets/images/icons/save-time-money.svg";
import WideBanner from "components/common/WideBanner";
import Maybe from "components/common/Maybe";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";

const ProductsPayroll = () => {
	const operations = [
		// {
		//     image: simplifyPeopleOprations1,
		//     tag: "PEOPLE OPS",
		//     title: "Easily Manage Your People",
		//     description: "Earnipay has all the HR tools that make managing your people easy, by providing a single point of access for onboarding, employee information, benefits and on-demand pay. In 3-quick steps you can securely automate your HR tasks while you focus on other important things.",
		//     imageFirst: true,
		// },
		{
			image: simplifyPeopleOprations2,
			tag: "PAYROLL PROCESSING",
			title: "The Simplest Way To Pay Your People",
			description:
				"Our simple payroll solution makes paying your people fast and easy. Whether you have a small team of 100 or a large team of 100,000+, all you need to do is upload your employee list once and let our intelligent business core do the rest. It will sort your employees in an easy-to-view way on your dashboard, automatically process your payroll, remove deductions as specified by you and remit salaries directly to your employees.",
			imageFirst: false,
		},
		{
			image: simplifyPeopleOprations3,
			tag: "EMPLOYEE BENEFITS",
			title: "On-Time and On-Demand Pay That Will Make Your Employees Happy!",
			description:
				"Earnipay offers on-time and on-demand pay to keep your employees happy and engaged. Our payroll solution helps you efficiently process payroll, improving employee satisfaction and reducing the risk of late or missed payments. Our On-demand pay service allows employees to access up to 50% of their earned salaries before payday, helping them cover unexpected expenses or emergencies.",
			imageFirst: true,
		},
		// {
		// 	image: simplifyPeopleOprations4,
		// 	tag: "PAYROLL FINANCING",
		// 	title: "Flexible Payroll Financing You Can Trust",
		// 	description:
		// 		"Our revolutionary payroll financing solution helps businesses meet their payroll obligations on time. With our low-cost financing options and cutting-edge digital tools, you can access capital in real time to meet your payroll needs.",
		// 	imageFirst: false,
		// },
	];

	const muchMores = [
		{
			image: securityConfidentiality,
			title: "Security & Confidentiality",
			content:
				"Our unique payroll management system eliminates the possibility of confidential and sensitive payroll leaks.",
		},
		{
			image: employeeExp,
			title: "Improved Employee Experience",
			content:
				"We make onboarding your employees ridiculously easy. On day one, they will be ready to go and our advanced benefit tools like on-demand pay make employee retention a breeze.",
		},
		{
			image: compliant,
			title: "Stay Compliant and reduce the risk of errors",
			content:
				"Earnipay simplifies compliance for your business, automating many of the HR tasks and government filings for you. Our built-in safeguards help prevent mistakes that could cost your business money.",
		},
		{
			image: saveTimeMoney,
			title: "Save Time & Money with Benefits and Payroll all-in-one place",
			content:
				"Not only can Earnipay save you time, it can also help you save money by streamlining your process and reducing the need for multiple subscriptions or licenses.",
		},
	];

    useEffect(() => {
        googleAnalyticsTrack();
    }, [])

	return (
		<div>
			
			<DynamicHeadTag headerText="Payroll processing and Financing" />
			
			<div className="relative bg-ep-primary-light">
				<div className="container">
					<div className="flex justify-between xl:h-[800px] -mt-10">
						<div className="w-full md:w-1/2 my-auto py-20 md:py-24 lg:py-36">
							<div>
								<div className="pt-4 md:pt-0 text-3xl sm:text-4xl lg:text-5xl text-ep-primary font-bold font-aeonik">
									Transform the way you handle Payroll
								</div>
								<div className="mt-6 text-base text-gray-600 font-aeonik">
									Our Payroll solution is made for teams of any size, 
									whether they are small, big, freelance, in-person, or remote. 
									Your employees get paid accurately and on time, every time.
								</div>
								{/* <div className="mt-10 sm:flex sm:flex-wrap sm:space-x-6 md:space-x-0 lg:space-x-6 space-y-2 sm:space-y-0 md:space-y-2 lg:space-y-0">
									<Link to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }} target="_blank" rel="noreferrer" className="btn btn-ep-primary">
										Get Started
									</Link>
								</div> */}
							</div>
						</div>

						<div className="w-1/2 h-full p-20 absolute bottom-0 right-0 hidden md:flex justify-center bg-hero-circle-full">
							<img
								src={heroImage}
								alt="earnipay hero"
								className="object-contain max-w-xl"
							/>
						</div>
					</div>
				</div>
				<div></div>
			</div>

			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title hidden">
					Magically Simplify Your People Operations
				</div>
				{/* <div className="-mt-8 sm:-mt-4"> */}
				<div className="-mt-8">
					{operations.map((operation, index) => (
						<div
							key={index}
							className="mt-24 md:mt-36 flex flex-col md:flex-row md:justify-between">
							<div
								className={
									"md:w-1/2 items-center mr-28 flex " +
									(!operation.imageFirst && "md:hidden")
								}>
								<div className="w-full flex">
									<div className="min-h-48 relative inline-block mx-auto md:mx-0">
										<img
											src={operation.image}
											className="object-contain max-h-[300px] md:max-h-full"
											alt={operation.title}
										/>
									</div>
								</div>
							</div>
							<div className="md:w-1/2 flex items-center">
								<div className="mt-8 md:mt-0">
									<div className="relative z-[2]">
										<div className="text-ep-primary-400 text-xs font-semibold">
											{operation.tag}
										</div>
										<div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik">
											{operation.title}
										</div>
										<div className="mt-6 text-gray-600 text-base">
											{operation.description}
										</div>
									</div>
								</div>
							</div>
							<Maybe condition={!operation.imageFirst}>
								<div className="md:w-1/2 items-center hidden md:flex ml-28">
									<div className="w-full flex">
										<div className="min-h-48 relative inline-block mx-auto md:mx-0">
											<img
												src={operation.image}
												className="object-contain max-h-[300px] md:max-h-full"
												alt={operation.title}
											/>
										</div>
									</div>
								</div>
							</Maybe>
						</div>
					))}
				</div>
			</div>

			<div className="container mt-20 sm:mt-24 lg:mt-32">
				<div className="lg:flex">
					<div className="w-full lg:w-1/3 flex lg:flex-col lg:pr-6 lg:space-y-2">
						<div
							className="hidden lg:block page-title text-ep-primary"
							style={{ textAlign: "left", margin: "0" }}>
							And So Much <br className="hidden xl:block" /> More…
						</div>
						<div className="block lg:hidden page-title text-ep-primary">
							And So Much <br className="hidden xl:block" /> More…
						</div>
					</div>
					<div className="w-full lg:w-2/3 mt-12 lg:mt-0">
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
							{muchMores.map((muchMore, index) => (
								<div key={index} className="flex">
									<div className="w-12 sm:w-20 h-12 sm:h-20 p-3 sm:p-4 lg:p-5 flex flex-shrink-0 mr-6 bg-ep-primary-light rounded-md">
										<img
											src={muchMore.image}
											alt={muchMore.title}
											className="w-full h-full my-auto object-contain"
										/>
									</div>
									<div>
										<div className="font-bold text-ep-primary-500">
											{muchMore.title}
										</div>
										<div className="mt-3 text-gray-600 text-base">
											{muchMore.content}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<WideBanner
				title="Get Better Business Outcomes with Earnipay"
				description={
					<>
						Let us empower your business with our range of{" "}
						<br className="hidden xl:block" /> finance and people solutions
					</>
				}
				buttonLink={"https://business.earnipay.com/onboarding/get-started"}
				buttonText="Get Started"
				openInNewTab={true}
			/>
		</div>
	);
};

export default ProductsPayroll;
