import React, { useEffect, useState } from "react";
import loanSteps1 from "assets/images/bgs/loan-steps-img-1.svg";
import loanSteps2 from "assets/images/bgs/loan-steps-img-2.svg";
import loanSteps3 from "assets/images/bgs/loan-steps-img-3.svg";
// import directionArrowActive from "assets/images/icons/direction-down-circle-active.svg";
// import directionArrowInactive from "assets/images/icons/direction-down-circle-inactive.svg";
// import Maybe from "components/common/Maybe";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import creditHeroImg from "assets/images/bgs/credit-hero-img.svg";
// import arrowWhite from "assets/images/icons/arrow-right-white.svg";
import creditFastTrackImg from "assets/images/bgs/credit-fast-track-dreams.svg";
// import creditLoansImg from "assets/images/bgs/credit-loans-frame-img.svg";
import creditPerksImg1 from "assets/images/bgs/credit-perks-img-1.svg";
import creditPerksImg2 from "assets/images/bgs/credit-perks-img-2.svg";
import creditPerksImg3 from "assets/images/bgs/credit-perks-img-3.svg";
import creditPerksImg4 from "assets/images/bgs/credit-perks-img-4.svg";
// import gettingLoanFewStepsImg from "assets/images/bgs/getting-loan-few-steps.svg";
// import directionRightCircle from "assets/images/icons/direction-right-circle.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import mobileGreenDiamondBg from "assets/images/bgs/mobile-green-diamond-bg.svg";
import goldenLineMobile from "assets/images/money-that-works-mobile.svg";
import goldenLineBg from "assets/images/bgs/golden-diamond-line-bg.svg";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import { useMediaQuery } from "hooks/useMediaQuery";
import savingsVector from "assets/images/bgs/loans-landing-vector.svg";





const ProductsBulkPayment = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	const creditPerks = [
		{
			img: creditPerksImg1,
			title: "Available On Mobile",
			content: "We assess you completely on mobile with no long forms or complex application processes"
		},
		{
			img: creditPerksImg2,
			title: "Ethically Priced",
			content: "Our rates are benchmarked on the best bank rates. As a policy, we do not offer exorbitant or predatory rates."
		},
		{
			img: creditPerksImg3,
			title: "Transparent Terms",
			content: "Our terms are always clearly stated with no sneaky fine print or hidden fees and charges."
		},
		{
			img: creditPerksImg4,
			title: "Quick Decisions",
			content: "We respond quickly to your request with no ambiguity or ever-changing requirements."
		},

	];

	const [activeLoanStep, setActiveLoanStep] = useState(0);

	const loanSteps = [
		{
			image: loanSteps1,
			title: "Complete Your KYC to Access Loans",
			content:
				"To be eligible for loan requests on the Earnipay app, you will need to complete our KYC process",
		},
		{
			image: loanSteps2,
			title: "Get Your Limit",
			content:
				"Access your loan limit after your bank statement is analysed",
		},
		{
			image: loanSteps3,
			title: "Loan Disbursement",
			content:
				"Choose your preferred loan amount and repayment period, get your loan offer and access your funds.",
		},
	];


    useEffect(() => {
        googleAnalyticsTrack();
    }, [])

	return (
		<div>
			
			{/* <div className="text-5xl font-medium text-center text-ep-primary-600 pt-20" 
							style={{color: '#093D42'}}
						>
						Money Made Easy For<br />  Businesses And People
						</div> */}

			<DynamicHeadTag
				headerText="Bulk Airtime, Data and Vendor payments | Earnipay"
				headerDescription="Pay up to 3,000+ Bills in less than 1 minute. Buy airtime, data and much more for your teams and business."
			/>

				<section className="relative">
					<div className="flex flex-col items-center text-center page-header-gradient-half bg-ep-primary-light pt-16">
						<div className="text-2xl md:text-5xl font-medium text-ep-primary powering-business-titles">
							Borrow Better, Achieve More
						</div>
						<div className="text-gray-600 mt-6 text-[12px] md:text-xl w-full access-high-yields">
							Access affordable loans to bring your dreams to life
						</div>
						<div className="mt-12 first-landing-playstore-btns">
							<PlayStoreButtons />
						</div>

						<div className="mt-8 mb-16 flex justify-end">
							<img src={creditHeroImg} className="w-3/4" alt="" />
						</div>

						<img src={savingsVector} alt="" className="vector-header absolute" />
					</div>
				</section>


				<div className="flex justify-center px-6 md:px-10 lg:px-14 pb-20">
					<div className="flex flex-col md:flex-row justify-center md:space-x-10 pt-10 max-w-[1200px] space-y-10 md:space-y-0 items-stretch">
						<div className="flex-1 max-w-[560px]  md:space-y-6 md:px-4">
							<div className="text-[28px] md:text-2xl lg:text-5xl max-w-[300px]">Fast-Track <span className="lg:block md:pt-2">Your Dreams</span></div>
							<div className="text-gray-500 mt-2 text-sm md:text-xl lg:text-2xl">Whether for vacation, a car, or a new phone, don't wait for inflation to hit. Get assessed and approved for ethically priced loans in minutes.</div>
							<button className="header-signup-btn mt-5">Apply For A Loan</button>
						</div>
						<div className="flex-1 flex justify-center items-center">
							<img src={creditFastTrackImg} className="w-full mx-auto object-cover" alt="" />
						</div>
					</div>
				</div>

				<div className='pt-20'>
					<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
						backgroundImage: `url(${isMobile ? mobileGreenDiamondBg : greenDiamondBg})`,
						backgroundRepeat: 'no-repeat',
					}}>
						<div className='flex flex-col items-center'>
							<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Loans Don't Have To Be Scary</div>
							<div className="text-[#494C4D] money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
								Your financial health is our biggest desire, and we have designed our loans to work for you, not against you.
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-5 md:p-20 max-w-[936px] justify-center mx-auto">
					{creditPerks.map((item, index) => (
						<div key={index} className="shadow-lg border border-gray-100 p-10 rounded-3xl ">
							<img src={item.img} alt="" />
							<div className="mx-auto">
								<div className="font-bold py-3">{item.title}</div>
								<div className="text-gray-500 text-sm">{item.content}</div>
							</div>
						</div>
					))}
				</div>

				<div className='pt-20'>
					<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
						backgroundImage: `url(${isMobile ? goldenLineMobile : goldenLineBg})`,
						backgroundRepeat: 'no-repeat',
					}}>
						<div className='flex flex-col items-center space-y-3'>
							<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Getting A Loan Takes A Few Steps</div>
							<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
							Access the financing you need to take that next step
							</div>
						</div>
					</div>
				</div>

				{/* <div className='py-20'>
					<div className="flex flex-col justify-center my-20" style={{backgroundImage: `url(${orangeDiamondBg})`, height: '34px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
						<div className='flex flex-col items-center'>
							<div className="md:text-[56px] leading-none text-center ">Getting A Loan Takes A Few Steps</div>
							<div className="text-gray-600 text-2xl text-center max-w-[600px] flex-wrap mt-3">
								Access the financing you need to take that next step
							</div>
						</div>
					</div>
				</div> */}
					

					<div className="container mt-14 sm:mt-20 lg:mt-28">
						<div className="mt-10 lg:flex bg-[#106368] rounded-xl">
							<div className="w-full lg:w-2/5 flex flex-col lg:flex-col lg:pr-20 lg:space-y-5 justify-center p-10">
								{[...Array(3)].map((data, index) => (
									<div key={index} onClick={() => setActiveLoanStep(index)} className={`
										${activeLoanStep === index ? "border-[#B6ECF2]" : "border-transparent lg:border-[#1a99a1]"}
										${activeLoanStep === index ? "text-white" : "text-red-600"}
										w-full lg:mr-16 px-4 flex flex-col justify-between lg:border-b-0 lg:border-l-[2px] rounded-sm cursor-pointer`}
									>
										<div className={`text-base mx-auto lg:mx-0 ${activeLoanStep === index ? "text-white" : "text-[#b6ecf281]"}`}>
											<div className="text-lg font-medium">{loanSteps[index].title}</div>
											<div className={`text-ep-primary-light text-sm ${activeLoanStep === index ? "text-white" : "text-[#b6ecf281]"}`}>{loanSteps[index].content}</div> 
										</div>
									</div>
								))}
							</div>
							<div className="w-full lg:w-3/5 justify-center sm:flex sm:space-x-12 rounded-xl">
								<div className="mt-8 sm:mt-0 sm:w-1/2 max-h-[400px]">
									<img src={loanSteps[activeLoanStep].image} alt={loanSteps[activeLoanStep].title} className="w-full h-full object-contain" />
								</div>
							</div>
						</div>
					</div>

					{/* faq section here */}

			{/* <WideBanner
				title="Get Better Business Outcomes with Earnipay"
				description={
					<>
						Let us empower your business with our range of{" "}
						<br className="hidden xl:block" /> finance and people solutions
					</>
				}
				buttonLink={"https://business.earnipay.com/onboarding/get-started"}
				buttonText="Get Started"
				openInNewTab={true} */}
		</div>
	);
};

export default ProductsBulkPayment;
