import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import heroImage from "assets/images/bgs/employer-hero.png";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import financeIcon from "assets/images/icons/finance-icon.svg";
import peopleOps from "assets/images/icons/people-ops.svg";
import employeeManagement from "assets/images/icons/employee-management.svg";
import TrustedBy from "components/common/trustedBy";
import WideBanner from "components/common/WideBanner";
import BulkPayFeature from "components/landing-page/BulkPayFeature";
import PayrollFeature from "components/landing-page/PayrollFeature";
import OnDemandFeature from "components/landing-page/OnDemandFeature";


const Employers = () => {

	const transformBusinessOptions = [
		{
			image: financeIcon,
			title: "Finance",
			description: "Our finance features helps your business optimize your financial processes and achieve better results. With our digital tools, you can manage your spending, factor invoices, pay bills in bulk, and manage your vendors more efficiently. By bringing all of your financial data together in one place, you can easily see how your business is performing and identify areas for improvement.",
		},
		{
			image: peopleOps,
			title: "People Ops",
			description: "With Earnipay, businesses like yours can manage their employee information securely and confidently. With our people ops tools, you can easily stay compliant with payroll regulations and avoid errors or oversights. Our advanced business core technology handles your payroll seamlessly, guaranteeing timely payment for your valued employees.",
		},
		{
			image: employeeManagement,
			title: "Employee Benefits",
			description: "Our employee benefits solution is designed to help you improve your employee experience. You can become an employer of choice, attract and retain top talent, and save your business from lost revenue and productivity, by offering your employees on-demand pay and other benefits from Earnipay. This helps you create a positive work environment and stand out in a competitive job market.",
		},
	];

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>


			<DynamicHeadTag
				headerText="Earnipay | For Employers"
				headerDescription="Simplify your business processes for growth and empower your employees. Streamline payroll, access funds for business, reduce costs and provide your employees with financial stability."
			/>


			<div className="relative bg-ep-primary-light">
				<div className="container">
					<div className="flex justify-between xl:h-[800px] -mt-10">
						<div className="w-full md:w-1/2 my-auto py-20 md:py-24 lg:py-36">
							<div>
								<div className="pt-4 md:pt-0 text-3xl sm:text-4xl lg:text-5xl text-ep-primary font-bold font-aeonik">
									A Powerful Suite of
									<br className="hidden xl:block" /> Solutions Built For
									<br className="hidden xl:block" /> Businesses Like You
								</div>
								<div className="mt-6 text-base text-gray-600 font-aeonik">
									Earnipay's integrated platform is designed to help businesses like yours streamline their operations and focus on what matters most. By bringing together finance, operations, and people management all in one place, we help eliminate silos and provide complete visibility and control.
								</div>
								<div className="mt-10 sm:flex sm:flex-wrap sm:space-x-6 md:space-x-0 lg:space-x-6 space-y-2 sm:space-y-0 md:space-y-2 lg:space-y-0">
									<Link to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }} target="_blank" rel="noreferrer" className="btn btn-ep-primary">
										Get Started
									</Link>
								</div>
							</div>
						</div>

						<div className="w-1/2 h-full p-20 absolute bottom-0 right-0 hidden md:flex justify-center bg-hero-circle-full">
							<img src={heroImage} alt="earnipay hero" className="object-contain max-w-xl" />
						</div>
					</div>
				</div>
				<div></div>
			</div>
			<TrustedBy />

			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title">
					Transform your business
				</div>
				<div className="mt-10 lg:mt-12">

					<div className="grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-10">
						{transformBusinessOptions.map((option, index) => (
							<div key={index} className="flex">
								<div className="w-12 sm:w-14 h-12 sm:h-14 p-3 flex flex-shrink-0 mr-6 bg-ep-primary-light rounded-md">
									<img
										src={option.image}
										alt={option.title}
										className="w-full h-full my-auto object-contain"
									/>
								</div>
								<div>
									<div className="font-semibold">
										{option.title}
									</div>
									<div className="mt-3 text-gray-600 text-base">
										{option.description}
									</div>
								</div>
							</div>
						))}
					</div>

				</div>
			</div>


			<div className="pt-4 pb-2">

			</div>


			{/* <div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="page-title text-ep-primary">
					<div>We've got a solution for everyone</div>
					<div className="max-w-3xl">
						No matter your industry, Earnipay will provide your business with the digital tools it needs to thrive
					</div>
				</div>
				<div className="mt-10 lg:mt-12">

					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 sm:gap-16">
						{solutions.map((solution, index) => (
							<Link to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }} target="_blank" rel="noreferrer" key={index} className="max-w-xs mx-auto">
								<div className="h-auto bg-ep-primary-light rounded-md">
									<img src={solution.image} alt={solution.title} className="w-full h-full object-cover" />
								</div>
								<div className="mt-6">
									<div className="font-medium text-2xl text-ep-primary-600 hover:underline">
										{solution.title}
									</div>
									<div className="mt-4 text-gray-600 text-sm">
										{solution.description}
									</div>
									<div className="w-max mt-4 text-ep-primary-600 text-sm flex items-center space-x-3 group">
										<span className="group-hover:underline">Learn More</span>
										<img src={arrowRight} alt="arrow" className="h-4" />
									</div>
								</div>
							</Link>
						))}
					</div>


			
				</div>
			</div> */}


			{/* <Testimony /> */}


			<BulkPayFeature />
			{/* <ExcellenceFeature /> */}

			<PayrollFeature />

			<div className="mt-10">
				<OnDemandFeature />
			</div>

			<WideBanner
				title="Get Better Business Outcomes with Earnipay"
				description="Let us empower your business with our range of finance and people solutions"
				buttonLink={"https://business.earnipay.com/onboarding/get-started"}
				buttonText="Get Started"
				openInNewTab={true}
			/>


		</div>
	);
};

export default Employers;
