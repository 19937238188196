import React from 'react';
import earnipayLogoWhite from 'assets/images/icons/earnipay-white.svg';
import dollarJar from 'assets/images/bgs/dollar-coin-jar.svg'
import greenRightArrow from 'assets/images/icons/green-right-arrow.svg';
import dollarJarBg from 'assets/images/bgs/dollar-jar-bg.svg';

const DollarSavingsOtw = () => {
  return (
    <div>
        <div className='flex flex-col md:flex-row justify-between items-center text-white rounded-2xl' style={{backgroundImage: `url(${dollarJarBg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
            <div className='flex-1 p-10 space-y-5'>
                <div><img src={earnipayLogoWhite} alt="" /></div>
                <div className='text-[42px] md:text-[56px] lg:text-[80px] font-bold leading-none'>Dollar Savings on the Way!</div>
                <div><button className='text-ep-primary p-3 px-3 md:px-6 md:p-3 rounded-3xl bg-white'>Join the Waitlist <img src={greenRightArrow} className='inline-block' alt="" /></button></div>
            </div>
            <div className='flex-1'><img src={dollarJar} alt="" className='mix-blend-luminosity'/></div>
        </div>
    </div>
  )
}

export default DollarSavingsOtw