import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import logger from "utils/logger";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import TrustedBy from "components/common/trustedBy";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import goldenLineBg from "assets/images/bgs/golden-diamond-line-bg.svg";
import mainSectionImg from "assets/images/main-section-image-2.svg";
import bgLineVector from "assets/images/bg-line-header.svg";
import yieldImageBg from "assets/images/bgs/total-yield-landing-bg.svg";
import earnipayByTheNumbersBg from "assets/images/bgs/earnipay-by-numbers-green-bg.svg";
import arrowWhite from "assets/images/icons/arrow-right-white.svg";
import anydayPayday from "assets/images/bgs/anyday-can-be-payday-img.svg";
import leadingRates from "assets/images/bgs/leading-rates-img.svg";
import betterMoneyDecisions from "assets/images/bgs/better-money-decisions.svg";
import upgradeForMore from "assets/images/bgs/upgrade-for-more.svg";
import dataPrivacyLockImg from "assets/images/icons/data-privacy-yellow-lock.svg";
import bankSecurityImg from "assets/images/icons/bank-level-security-blue-shield.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import landingNetBg from "assets/images/bgs/landing-net-bg.svg";
import businessToolsLanding1 from "assets/images/features/business-tools-landing-1.svg";
import businessToolsLanding2 from "assets/images/features/business-tools-landing-2.svg";
import businessToolsLanding3 from "assets/images/features/business-tools-landing-3.svg";
import businessToolsLanding4 from "assets/images/features/business-tools-landing-4.svg";
import businessToolsLanding5 from "assets/images/features/business-tools-landing-5.svg";
import businessToolsLanding6 from "assets/images/features/business-tools-landing-6.svg";
import goldenLineMobile from "assets/images/money-that-works-mobile.svg";
import createBusinessButtonImgMobile from "assets/images/create-business-acct.svg";
import CtaSection from "components/common/cta-green";
import Testimonials from "components/common/testimony";
import greenButton from "../../assets/images/icons/green-button-revamp.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import { TextChanger } from "components/common/text-changer";
import { useMediaQuery } from "hooks/useMediaQuery";

const CHANGING_TEXTS = ["Prosperity", "Growth", "Success", "Progress"];

const GET_PERSONAL_LIST = [
	{
		image: anydayPayday,
		title: "Any day can be payday",
		text: (
            <>
                <span>Get access to your salary all day any day with</span>
                <br />
                <span>Salary On-Demand</span>
            </>
        ),
	},
	{
		image: leadingRates,
		title: "Grow your money with Market leading rates",
		text: (
            <>
                <span>With our Goal, Flex, and USD savings plans, </span>
                <br />
                <span>growing your money has never been easier.</span>
            </>
        ),
	},
	{
		image: betterMoneyDecisions,
		title: "Better Money Decisions",
		text: (
            <>
                <span>With insights into how you earn and spend, you </span>
                <br />
                <span>can now do money better</span>
            </>
        ),
	},
	{
		image: upgradeForMore,
		title: "Upgrade for more",
		text: (
            <>
                <span>Complete your verification in less than 3 minutes </span>
                <br />
                <span>and get access to rewards, loans, and a better </span>
                <br />
                <span>money experience.</span>
            </>
        ),
	},
]

const Home = () => {
    const location = useLocation();

    const isMobile = useMediaQuery('(max-width: 500px)');

    const securityFeatures = [
        {
            title: "Data Privacy",
            content:
                "We prioritize protecting your data. Our secure servers ensure that no third-party is granted access to your personal information. We collect and use your personal data to constantly improve your user experience.",
            image: dataPrivacyLockImg,
        },
        {
            title: "Bank Level Security",
            content:
                "All application traffic is encrypted and protected by using 256-bit AES bank level encryption. This provides security between devices and our servers ensuring personal and transactional details are always kept private",
            image: bankSecurityImg,
        },
    ];

    const businessTools = [
        {
            img: businessToolsLanding1,
            title: "Run and Scale Business Operations",
            content:
                "Access tools that make it easy to scale your business operations efficiently.",
        },
        {
            img: businessToolsLanding2,
            title: "Maximize Idle cash",
            content:
                "Earn interest on idle cash with zero fees, charges, or minimum holding period.",
        },
        {
            img: businessToolsLanding3,
            title: "Access Business Financing On-Demand",
            content:
                "Access fast and convenient financing options tailored to your business needs.",
        },
        {
            img: businessToolsLanding4,
            title: "Manage Cashflow",
            content:
                "Track payments, monitor spending, and oversee your business transactions from a single, intuitive dashboard.",
        },
        {
            img: businessToolsLanding5,
            title: "Process Payroll",
            content:
                "Streamline your payroll process and ensure accurate and timely payments for your employees.",
        },
        {
            img: businessToolsLanding6,
            title: "Enable On-Demand Pay",
            content:
                "Become the employer of choice by providing employees with on-demand access to their earned salaries whenever they need it",
        },
    ];

    useEffect(() => {
        logger("website-homepage");
        googleAnalyticsTrack();

        const qs = queryString.parse(location.search);
        if (qs.referral !== undefined && qs.referral !== null) {
            window.location.href =
                process.env.REACT_APP_EMPLOYEE_APP_URL +
                "/get-started/referral/" +
                qs.referral;
        }
    }, [location]);

    return (
        <div>
            <DynamicHeadTag
                headerText="Earnipay Business Solutions | Streamline, Grow, and Scale"
                headerDescription="Earnipay simplifies business operations with a single dashboard. From high-yield accounts and flexible credit options to efficient payments, payroll processing, and on-demand pay, streamline your financial operations and drive your business growth."
            />
            <section className="w-full bg-ep-primary-light page-header-gradient-half pb-10 mt-0">
                <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />
                <div className="flex flex-col justify-center items-center">
                    <div className="">
                        <div className="text-xl md:text-[60px] font-medium text-center text-[#093D42] leading-snug pt-20 powering-business-title">
                            <span>Powering Businesses And</span>
							<br />
							<span>People For </span>
							<TextChanger 
							textList={CHANGING_TEXTS}
								textClassName="italic text-[#E8912C]"
							/>
                        </div>
                        <div className="text-sm md:text-xl text-[#000000B2] text-center mt-5 font-normal access-high-yields">
                            Access high-yield accounts, loans, and all your
                            financial tools in one platform.
                        </div>
                        <div className="pt-10 flex justify-center">
                            <div className="relative w-[320px] h-[52px] cursor-pointer create-business-acct">
                                <img
                                    src={isMobile ? createBusinessButtonImgMobile : greenButton}
                                    alt=""
                                    className="w-full h-full object-cover"
                                />
                                <div className={`absolute top-1/2 left-44 pl-2 transform text-white w-full ${isMobile ? "create-business-inner-div" : "-translate-x-1/2 -translate-y-1/2"}`}>
                                    <Link
                                        to={{
                                            pathname:
                                                "https://business.earnipay.com/onboarding/get-started",
                                        }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Create A Business Account
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mt-12 first-landing-playstore-btns">
                        <PlayStoreButtons />
                    </div> */}

                </div>
            </section>

            <div
                className="flex justify-center mb-16 pb-5 min-h[210px] landing-page-header-gradient"
            >
                <img
                    className="w-full landing-page-laptop-img"
                    src={mainSectionImg}
                    alt=""
                    loading="lazy"
                />
            </div>

            <section
                className=" w-full h-auto bg-contain bg-center flex justify-center"
                style={{
                    backgroundImage: `url(${isMobile ? goldenLineMobile : goldenLineBg})`,
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="flex flex-col text-xl justify-center items-center p-5 py-4 md:py-8 lg:py-12">
                    <div className="lg:text-[56px] md:text-2xl text-xl leading-none text-center">
                        Money That Works For You
                    </div>
                    <div className="text-sm md:text-lg lg:text-2xl text-center min-w-[240px] text-gray-600 pt-5 money-that-works-sub-text">
                        A powerful suite of solutions designed to make your
                        money work for you
                    </div>
                </div>
            </section>

            <div className="mt-10 flex justify-center yield-earned-img">
                <img
                    className="w-full"
                    src={yieldImageBg}
                    alt=""
                    loading="lazy"
                />
            </div>

            <div className="mt-20 leading-business-wrapper">
                <TrustedBy />
            </div>

            <div>
                <div
                    style={{
                        backgroundImage: `url(${earnipayByTheNumbersBg})`,
                    }}
                    className="text-white  max-w-full green-grid-wrapper"
                >
                    {/* min-h-[200px] max-h-[620px] */}
                    <div className="container flex earnipay-by-the-number-section gap-6 w-full h-full">
                        <div className="flex w-1/2 earnipay-numbers-label">
                            <div className="flex flex-col justify-center text-left">
                                <div className="text-center md:text-left text-xl green-grid-section-title-line-height font-medium max-w-[400px]">
                                    Earnipay By The Numbers
                                </div>
                                <div className="text-xs earnipay-by-the-no-text md:text-2xl py-5">
                                    We have helped businesses and people do more
                                    with money, and this is just the beginning.
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 flex justify-end earnipay-numbers">
                            <div className="flex md:space-y-12 md:flex-col items-centers justify-center text-left">
                                <div>
                                    <div className="text-xl green-grid-section-title-line-height font-medium">
                                        &#8358;3B+
                                    </div>
                                    <div className="text-xs md:text-2xl py-1 pl-1">
                                        Loans Disbursed
                                    </div>
                                </div>
                                <div>
                                    <div className="text-xl green-grid-section-title-line-height font-medium">
                                        &#8358;1B+
                                    </div>
                                    <div className="text-xs md:text-2xl py-1 pl-1">
                                        In Salaries Accessed
                                    </div>
                                </div>
                                <div>
                                    <div className="text-xl green-grid-section-title-line-height font-medium">
                                        20k+
                                    </div>
                                    <div className="text-xs md:text-2xl py-1 pl-1">
                                        Salary Earners Served
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-center pt-10">
                <div className="flex flex-col justify-center items-center max-w-[750px]">
                    <div className="font-medium my-6 text-xl text-center manage-your-business-title">
                        All The Tools To Manage Your Business In One Place
                    </div>
                    <div className="get-earnipay-landing-btn mb-10">
                        <Link
                            to={{
                                pathname:
                                    "https://business.earnipay.com/onboarding/get-started",
                            }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Get Earnipay
                        </Link>
                        <img
                            src={arrowWhite}
                            alt=""
                            className="pl-2"
                        />
                    </div>
                </div>
            </div>

            <section className="flex flex-wrap justify-between p-3 md:p-10 business-tools-wrapper">
                {businessTools.map((tool, index) => (
                    <div
                        key={index}
                        className="w-full business-tool"
                    >
                        <div className="flex flex-col justify-around items-center h-full p-5 pb-6 bg-[#F9FAFB] rounded-xl">
                            <div className="business-tools-header">
                                <img
                                    className="p-2"
                                    src={tool.img}
                                    alt=""
                                    loading="lazy"
                                />
                            </div>
                            <div className="business-tool-body text-center p-1">
                                <div className="text-base lg:text-xl font-medium">
                                    {tool.title}
                                </div>
                                <div className="text-gray-500 text-xs lg:text-base mt-2">
                                    {tool.content}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>

            <div className="flex justify-center items-center space-x-5 my-5 landing-page-signup">
                <div className="sign-up-landing-btn border-1 border-green-400">
                    <Link
                        to={{
                            pathname:
                                "https://business.earnipay.com/onboarding/get-started",
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Sign Up In Minutes
                    </Link>
                </div>
                <div className="contact-sales-landing-link md:underline cursor-pointer border-2 md:border-none btn rounded-3xl md:rounded-none md:p-3 md:px-4 font-medium">
                    <Link
                        to={{ pathname: "mailto: business@earnipay.com" }}
                        onClick={(e) => {
                            window.location = e.target.getAttribute("href");
                            e.preventDefault();
                        }}
                    >
                        Contact Sales
                    </Link>
                </div>
            </div>

            <div className="mt-24 lg:mt-0 flex justify-center text-center get-personal-title-wrapper">
                <div className="get-personal-title mt-24 font-medium">
                    Get Personal With Your
                    <br />
                    Money
                </div>
            </div>

			<div className="mt-10 flex justify-center get-personal-playstore-btns">
				<PlayStoreButtons />
			</div>
			<div className="get-personal-list container">
				{
					GET_PERSONAL_LIST.map(({ image, title, text }, gplid) => {
						const isBgPatternOnTheRight = gplid % 2 < 1;

						return (
							<div className={`get-personal ${isBgPatternOnTheRight ? "get-personal-right" : "get-personal-left"}`} key={`${title}-${gplid}`}>
								<div className="get-personal-img-wrapper">
									<img src={image} alt="" loading="lazy" />
								</div>
								<div className="get-personal-text-wrapper">
									<h4 className="get-personal-title">{title}</h4>
									<p className="get-personal-text">{text}</p>
								</div>
								<img 
									src={landingNetBg} 
									className={`get-personal-pattern 
										${isBgPatternOnTheRight ? "get-personal-pattern-right" : "get-personal-pattern-left"}`} 
									alt="" 
                                    loading="lazy"
								/>
							</div>
						)
					})
				}
			</div>

            <div className="mt-10 mb-4 pt-20 flex justify-center security-title-wrapper">
                <div className="text-5xl font-medium security-title">
                    We Look After Your Money
                </div>
            </div>

            <div className="flex justify-center mt-8 mb-10 container">
                <div className="flex justify-between max-w-full rounded-3xl security-features p-5">
                    {securityFeatures.map((feature, index) => (
                        <div
                            key={index}
                            className="flex items-start p-6 space-x-5"
                        >
                            <div>
                                <div className="w-[64px] security-feature-img-wrapper">
                                    <img
                                        src={feature.image}
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div className="font-medium md:text-2xl security-feature-title">
                                    {feature.title}
                                </div>
                                <p
                                    className="text-base mt-2 md:text-lg security-feature-text"
                                    style={{ color: "#515357" }}
                                >
                                    {feature.content}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <CtaSection />

            <div className="mt-12">
                <div className="flex justify-center pt-10 pb-5 testimonial-landing-header-badge-wrapper">
                    <div className="testimonial-landing-header-badge">
                        Testimonials
                    </div>
                </div>

                <h3 className="w-full text-center font-medium text-5xl customers-saying container">
                    What Our Customers Are Saying About Us
                </h3>

                <Testimonials />
            </div>

            <OnePlatformForMoney />
        </div>
    );
};

export default Home;
