import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import heroImage from "assets/images/bgs/on-demand-hero.png";
import benefits1 from "assets/images/bgs/odp-benefits-1.svg";
import benefits2 from "assets/images/bgs/odp-benefits-2.svg";
import benefits3 from "assets/images/bgs/odp-benefits-3.svg";
import benefits4 from "assets/images/bgs/odp-benefits-4.svg";
// import directionArrowActive from "assets/images/icons/direction-down-circle-active.svg";
// import directionArrowInactive from "assets/images/icons/direction-down-circle-inactive.svg";
// import unlock from "assets/images/icons/unlock.svg";
// import time from "assets/images/icons/time.svg";
// import financialSupport from "assets/images/icons/financial-support.svg";
// import customize from "assets/images/icons/customize.svg";
// import Maybe from "components/common/Maybe";
// import WideBanner from "components/common/WideBanner";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
// import appStoreBlack from "assets/images/icons/app-store-black.svg";
// import playStoreBlack from "assets/images/icons/play-store-black.svg";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import onDemandPayHero from "assets/images/bgs/odp-hero.svg";
// import odpFrame from "assets/images/bgs/odp-frame.svg";
// import odpInstructionFrame from "assets/images/bgs/how-to-use-odp-frame.svg";
import OnePlatformForMoney from "components/common/one-platform-money-banner";
import Testimonials from "components/common/testimony";
import { PlayStoreButtons } from "components/common/play-store-buttons";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import orangeDiamondBg from "assets/images/bgs/orange-diamond-bg.svg";
import mobileOrangeDiamondBg from "assets/images/bgs/orange-diamond-mobile.svg";
import { useMediaQuery } from "hooks/useMediaQuery";


const ProductsOnDemandPay = () => {

	const isMobile = useMediaQuery('(max-width: 500px)');

	const odpBenefits = [
		{
			image: benefits1,
			title: "Instant Access",
			content:
				"Get immediate access to your earned wages whenever you need them.",
		},
		{
			image: benefits2,
			title: "Financial Flexibility",
			content:
				"Handle unexpected expenses or emergencies without waiting for payday.",
		},
		{
			image: benefits3,
			title: "Zero Interest",
			content:
				"Say bye to predatory loans and yes to your earned salary.",
		},
		{
			image: benefits4,
			title: "Dedicated Support",
			content:
				"Access reliable customer support whenever you need assistance.",
		},
	];

	const odpCardSteps = [
        {
            // image: ,
            title: 'Get Instant Access With On-Demand Pay',
            content: 'Request access from your employer and receive your unique code to unlock earnings anytime.',
        },
        {
            // image: odpCardSteps2,
            title: 'Track Your Earnings Daily',
            content: "See your accrued earnings daily and how much you can access based on your employer's set percentage.",
        },
        {
            // image: odpCardSteps3,
            title: 'Withdraw On Your Terms',
            content: 'Withdraw and spend on the Earnipay app before payday—achieve financial flexibility.',
        },
    ]

    const [activeOdpStep, setActiveOdpStep] = useState(0);

	useEffect(() => {
		googleAnalyticsTrack();
	}, [])

	return (
		<div>

			<DynamicHeadTag
				headerText="On-Demand Pay for Employees | Earnipay"
				headerDescription="Earnipay helps you deliver on-demand pay that gives your employees the financial control they need to be more motivated and happier at work."
			/>

			<div className="flex flex-col items-center text-center pt-16 page-header-gradient-half">
				<div className="text-5xl font-medium text-ep-primary powering-business-title">
				Freedom to Choose How You <br /> Get Paid
				</div>
				<div className="text-gray-600 mt-3 access-high-yields">
				Access the salary you've earned as you earn it, anytime, any day.
				</div>
				<div className="pt-4 md:pt-10 first-landing-playstore-btns">
					<PlayStoreButtons />
				</div>

				<div className="mt-8 md:mt-0 mb-16 flex justify-center">
					<img src={onDemandPayHero} className=" w-11/12" alt="" />
				</div>
			</div>

			<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : greenDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Make Any Day Payday</div>
					<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
						Access your hard-earned salary when you need it, not just on payday
					</div>
				</div>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-10 md:gap-y-16 p-3 md:p-14 justify-center mx-auto">
				{odpBenefits.map((benefit, index) => (
					<div key={index} className=" border-2 border-gray-200/50 p-10 rounded-3xl max-w-[444px] mx-auto">
						<img src={benefit.image} alt="" />
						<div>
							<div className="font-bold">{benefit.title}</div>
							<div className="text-sm text-gray-500">{benefit.content}</div>
						</div>
					</div>
				))
				}
			</div>

			<div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">How To Use On-Demand Pay</div>
					<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
					Get access to your money in 3 steps
					</div>
				</div>
			</div>

			<div className="container mt-14 sm:mt-20 lg:mt-28">
				<div className="mt-10 lg:flex bg-[#106368] rounded-xl">
					<div className="w-full lg:w-2/5 flex lg:flex-col lg:pr-20 lg:space-y-5 justify-center p-10">
						{[...Array(3)].map((data, index) => (
							<div key={index} onClick={() => setActiveOdpStep(index)} className={`
								${activeOdpStep === index ? "border-[#B6ECF2]" : "border-transparent lg:border-[#1a99a1]"}
								${activeOdpStep === index ? "text-white" : "text-red-600"}
								w-full lg:mr-16 pl-4 flex justify-between border-b-2 lg:border-b-0 lg:border-l-[2px] rounded-sm cursor-pointer`}
							>
								<div className={`text-base mx-auto lg:mx-0 ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>
									<div className="text-lg font-medium">{odpCardSteps[index].title}</div>
									<div className={`text-ep-primary-light text-sm ${activeOdpStep === index ? "text-white" : "text-[#b6ecf281]"}`}>{odpCardSteps[index].content}</div> 
								</div>
							</div>
						))}
					</div>
					<div className="w-full lg:w-3/5 justify-center sm:flex sm:space-x-12 rounded-xl">
						<div className="mt-8 sm:mt-0 sm:w-1/2 max-h-[400px]">
							<img src={odpCardSteps[activeOdpStep].image} alt={odpCardSteps[activeOdpStep].title} className="w-full h-full object-contain" />
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-col justify-center my-10 md:my-20 bg-center bg-contain w-full h-auto" style={{
				backgroundImage: `url(${isMobile ? mobileOrangeDiamondBg : orangeDiamondBg})`,
				backgroundRepeat: 'no-repeat',
			}}>
				<div className='flex flex-col items-center'>
					<div className="md:text-3xl lg:text-[56px] leading-none text-center ">Hear From Our Users</div>
					<div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
						Discover how On-Demand Pay has transformed their financial lives.
					</div>
				</div>
			</div>

			<Testimonials />

			<OnePlatformForMoney />
		
		</div>
	);
};

export default ProductsOnDemandPay;
