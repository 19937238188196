// import heroBackgroundImage from "assets/images/bgs/business-payroll-line-bg.svg";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import businnessPayrollMainImg from "assets/images/bgs/business-payroll-main-img.svg";
import greenDiamondBg from "assets/images/bgs/green-diamond-bg.svg";
import { useMediaQuery } from 'hooks/useMediaQuery';
import greenDiamondBgMobile from "assets/images/bgs/green-diamond-bg-mobile.svg";
import payrollProcessImg1 from "assets/images/bgs/payroll-process-img-1.svg";
import payrollProcessImg2 from "assets/images/bgs/payroll-process-img-2.svg";
import payrollProcessImg3 from "assets/images/bgs/payroll-process-img-3.svg";
import payrollProcessImg4 from "assets/images/bgs/payroll-process-img-4.svg";
import payrollBenefitsImg1 from "assets/images/bgs/payroll-benefits-img-1.svg";
import payrollBenefitsImg2 from "assets/images/bgs/payroll-benefits-img-2.svg";
import payrollBenefitsImg3 from "assets/images/bgs/payroll-benefits-img-3.svg";
import payrollBenefitsImg4 from "assets/images/bgs/payroll-benefits-img-4.svg";
import payrollSubGreenDiamond from "assets/images/bgs/payroll-subscription-green-diamond.svg";
import payrollGreenCircle from "assets/images/icons/payroll-green-check-circle.svg";
import payrollGetStartedBtn from "assets/images/bgs/payroll-get-started-btn.svg";
import CtaSection from "components/common/cta-green";
import greenButton from '../../assets/images/icons/green-button-revamp.svg';
import payrollGreenHand from 'assets/images/icons/payroll-green-hand.svg';
import bgLineVector from "assets/images/bg-line-header.svg";
import diamondNetBg from "assets/images/bgs/diamond-net-bg.svg";


const BusinessPayroll = () => {

    const isMobile = useMediaQuery('(max-width: 500px)');

    const payrollFeatures = [
		{
			img: payrollProcessImg1,
			title: "Centralized Payroll Management",
			content: "Simplify compliance and benefits management. Automate pensions, taxes, payouts, and employee benefits—all from one intuitive dashboard"
		},
		{
			img: payrollProcessImg2,
			title: "Insights And Analytics",
			content: "Track payroll expenditures and forecast expenses to inform strategic workforce management decisions."
		},
		{
			img: payrollProcessImg3,
			title: "Pay Groups",
			content: "Create groups for simple batched application of salaries, bonuses, benefits, and more."
		},
		{
			img: payrollProcessImg4,
			title: "Alerts And Reminders",
			content: "Get customisable notifications on payroll events and timelines to ensure you never miss a deadline."
		},

	];

    const payrollBenefits = [
        {
            img: payrollBenefitsImg1,
            title: "Fast And Precise Automation",
            content: "Save time with automated payroll processes that streamline payroll calculations and reduce manual data entry errors."
        },
        {
            img: payrollBenefitsImg2,
            title: "Transparent Pricing",
            content: "Access transparent and affordable pricing with no hidden fees for payroll solutions that fit your budget."
        },
        {
            img: payrollBenefitsImg3,
            title: "Secure Data Handling",
            content: "Rest assured of the utmost data privacy of employee data with our NDPR compliant data handling processes."
        },
        {
            img: payrollBenefitsImg4,
            title: "Rich Analytics For Decisions",
            content: "Leverage rich, detailed reports for better insights and decision making."
        },
    ]

  return (
    <div>
        <DynamicHeadTag
            headerText="Earnipay | One Platform for All Your Money Needs"
            // headerDescription="Gain access, consolidate, and manage all your financial needs in one place."
        />

        <section 
            className="page-header-gradient-half"
            >
            <img src={bgLineVector} alt="bg line vector" className="bg-line-vector-header" />

            <div className="flex justify-center">
                <div className="flex flex-col max-w-[650px]">
                    <div className="text-5xl font-medium text-center text-ep-primary-600 pt-20 leading-tight powering-business-title" 
                        style={{color: '#093D42'}}
                    >
                        People-Focused Payroll For All Business Sizes
                    </div>
                    <div className="text-gray-600 text-center mt-5 access-high-yields">
                        Handle salaries, benefits, deductions, and more with ease.
                    </div>
                </div>
            </div>
                

            <div className="flex flex-col items-center">
                <div className='pt-10'>
                    <div className="relative w-[320px] h-[52px] cursor-pointer"> 
                        <img src={greenButton} alt="" className="w-full h-full object-cover" />
                        <div className="absolute top-1/2 left-2/4 transform -translate-x-1/2 -translate-y-1/2 text-white pr-6">
                            Get Started
                        </div>
                    </div>
                </div>
                <div className='pr-5 cursor-pointer'>
                    <div className='text-ep-primary underline my-5'>View Pricing</div>
                </div>
            </div>
        
            <div className="flex justify-center pt-10">
                <img src={businnessPayrollMainImg} alt="" style={{width:'70%',}} />
            </div>
        </section>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Built For Every Type Of Business</div>
            <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
            Manage and settle your growing team easily and on time.
            </div>
          </div>
        </div>

        {/* <section className="flex justify-center pt-20">
            <div className="container border rounded-xl p-4 grid grid-cols-1 lg:grid-cols-2 gap-8 p-20s">
                {payrollFeatures.map((feature, index) => (
                    <div key={index} className="p-3 rounded-2xl bg-[#F6F8FA]">
                        <img src={feature.img} alt="" />
                        <div>
                            <div className="font-bold">{feature.title}</div>
                            <div className="text-sm text-gray-500">{feature.content}</div>
                        </div>
                    </div>
                ))}
            </div>
        </section> */}

        <section className="flex justify-center pt-20">
            <div className="container border rounded-xl p-4 grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-[1200px]">
                {payrollFeatures.map((feature, index) => (
                    <div key={index} className="p-5 rounded-2xl bg-[#F6F8FA] max-w-[550px] mx-auto">
                        <img src={feature.img} alt="" />
                        <div className="px-2">
                            <div className="font-bold">{feature.title}</div>
                            <div className="text-sm text-gray-500">{feature.content}</div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
        
        <div className="" style={{backgroundImage: `url(${diamondNetBg})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: '100% 500px'}}>
                <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
                backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
                backgroundRepeat: 'no-repeat',
                }}>
                <div className='flex flex-col items-center'>
                    <div className="md:text-3xl lg:text-[56px] leading-none text-center ">What We Offer</div>
                    <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
                    Choosing our Payroll feature means getting the best out of your benefits management processes.
                    </div>
                </div>
                </div>

                <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-16 p-2 md:p-20 justify-center mx-auto">
                    {payrollBenefits.map((item, index) => (
                        <div key={index} className=" border-2 border-gray-200/50 p-10 rounded-3xl max-w-[444px] mx-auto">
                            <img src={item.img} alt="" />
                            <div>
                                <div className="font-bold">{item.title}</div>
                                <div className="text-sm text-gray-500 pt-2">{item.content}</div>
                            </div>
                        </div>
                    ))
                    }
                </div>        
        </div>

        <div className="flex flex-col justify-center my-20 bg-center bg-contain w-full h-auto" style={{
          backgroundImage: `url(${isMobile ? greenDiamondBgMobile : greenDiamondBg})`,
          backgroundRepeat: 'no-repeat',
          }}>
          <div className='flex flex-col items-center'>
            <div className="md:text-3xl lg:text-[56px] leading-none text-center ">Pricing</div>
            <div className="text-gray-600 money-that-works-sub-text md:text-2xl text-center max-w-[600px] pt-3">
            Only pay for what you use.
            </div>
          </div>
        </div>

        <div className="p-5">
            <div className="container grid grid-cols-1 lg:grid-cols-2 rounded-3xl bg-[#F6F8FA] md:p-10 h-full gap-8 py-5">
                <div className="order-2 md:order-2 flex items-center justify-center">
                    <img className="h-full w-full object-contain" src={payrollSubGreenDiamond} alt="" />
                </div>
                <div className="order-1 md:order-1 p-5 border bg-white rounded-3xl flex flex-col justify-between">
                    <div className="space-y-2">
                        <div><img src={payrollGreenHand} alt="" /></div>
                        <div style={{ maxWidth: '524px' }}>
                            <div className="font-medium text-xs md:text-2xl">Payroll Subscription</div>
                            <div className="text-gray-600 whitespace-normal pr-2 text-xs lg:text-xl">We offer market leading rates for Payroll. Our pricing is fully configurable based on what you need, and transparent with zero hidden charges.</div>
                        </div>
                    </div>
                    <div>
                        <div className="pt-4 space-y-2">
                            <div className="text-gray-600 text-sm font-medium">Platform Fee</div>
                            <div>
                                <span className="text-5xl font-bold">&#8358;0</span><span className="text-gray-500 pl-1">/monthly</span>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="space-y-2">
                                <div className="text-gray-600 font-medium text-sm">What's Included</div>
                                <div className="flex items-center space-x-3"><img src={payrollGreenCircle} alt="" />
                                    <div className="text-gray-700 text-[10px] md:text-base">Net Salary - ₦50 per employee </div>
                                </div>
                                <div className="flex items-center space-x-3"><img src={payrollGreenCircle} alt="" />
                                    <div className="text-gray-700 text-[10px] md:text-base">PAYE - ₦100 per employee </div>
                                </div>
                                <div className="flex items-center space-x-3"><img src={payrollGreenCircle} alt="" />
                                    <div className="text-gray-700 text-[10px] md:text-base">Pensions - ₦100 per employee </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 cursor-pointer">
                            <img src={payrollGetStartedBtn} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CtaSection />


    </div>
  )
}

export default BusinessPayroll