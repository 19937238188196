import greenCloudBg from "assets/images/bgs/green-cloud-bg.svg";
import earnipayFeatureStar from "assets/images/bgs/earnipay-feature-star.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const CtaSection = () => {
  return (
        <div className="container mt-1 text-white">
			<div className="rounded-3xl h-[450px] interested-in-section-wrapper " 
				style={{backgroundPosition: 'center',backgroundImage: `url(${greenCloudBg})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat'
				}}>			
				<div className="flex justify-between items-center px-6 interested-in-section h-full">							
					<div className="order-2 md:order-1 flex justify-center items-center">
						<div className="md:p-8 flex flex-col justify-center">
							<h3 className="interested-in-earnipay-title">
								Interested in Earnipay for 
								<br />
								your Business?
							</h3>
							<div className="flex items-center mt-16">
								<div className="interested-in-earnipay-btn">
									<Link to={{ pathname: "https://business.earnipay.com/onboarding/get-started" }} target="_blank"	rel="noreferrer">
										Create A Business Account
									</Link>
								</div>
								<div className="underline interested-in-earnipay-link">
									<Link to={{ pathname: "mailto: business@earnipay.com" }} onClick={(e) => { window.location = e.target.getAttribute("href"); e.preventDefault(); }}>
										Contact Sales
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="order-1 md:order-2">
						<img src={earnipayFeatureStar} className="h-44 md:h-72" alt="" />
					</div>
				</div>
			</div>
		</div>
  )
}

export default CtaSection