import React from 'react'
import demandFeatureImg from "assets/images/bgs/anyday-payday.png";


const OnDemandFeature = () => {
    return (
        <div className="container md:mt-0">
            <div className="bg-ep-primary-light rounded-lg md:rounded-3xl">
                <div className="sm:rounded-r-3xl pr-5 sm:rounded-s-lg flex flex-col pt-3 md:py-0 md:pr-0 md:flex-row md:justify-start md:space-x-28">
                    <div className="md:w-1/2 order-2 md:order-1 flex items-center">
                        <div className="mt-8 md:mt-0">
                            <div className="container relative z-[2] pl-12 pt-3 sm:pt-10 sm:pb-10 pb-6">
                                <div className="text-ep-primary-400 text-xs font-semibold ">
                                    ON-DEMAND PAY
                                </div>
                                <div className="mt-4 text-3xl sm:text-4xl font-medium font-aeonik text-ep-primary">
                                    Any day is Payday
                                </div>
                                <div className="mt-6 text-gray-600 text-base">
                                    Life doesn't wait for payday, and now employees no longer have to.
                                    Offer your people instant access to their salaries with Earnipay at ZERO cost.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-2/3 order-1 md:order-2 flex justify-end items-center ">
                        <div className="w-full flex justify-end ">
                            <div className="min-h-48 relative inline-block mx-auto md:mx-0 md:pr-5 lg:pr-0">
                                <img
                                    src={demandFeatureImg}
                                    className="object-contain max-h-[300px] md:max-h-full rounded-3xl pr-5 md:pr-0"
                                    alt="anyday payday"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnDemandFeature